import React, { useEffect, useState } from 'react'
import Select from "react-select";
import {
    CiFacebook,
    CiInstagram,
    CiLinkedin,
    CiPhone,
    CiTwitter,
} from "react-icons/ci";
import { Link, useNavigate } from 'react-router-dom';
import { LiaRupeeSignSolid } from 'react-icons/lia';
import { REAPI } from '../../../../../serviceLayer/Interceptor';
import { SocialLinksGroup } from '../../../../../utils/socialLinks/SocialLinksGroup';

export const Stickyform = ({ courseName, categoryName }) => {
            // Slugify function
const slugify = (text) => {
    return text
      .toString()
      .normalize("NFD") // Normalize accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .toLowerCase()
      .replace(/[^a-z0-9 +\-]/g, "") // Remove non-alphanumeric characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with one
  };

    // Retrieve user data from localStorage (for conditional rendering)
    const LocalStorage = localStorage.getItem("data");
    // parsing the data into JSON
    const parseData = JSON.parse(LocalStorage)
    // console.log(parseData&& parseData,"localDAta")

    // navigation 
    const navigate = useNavigate();
    // states
    // storing the fetched trainer data in this state
    const [trainer, setTrainer] = useState([]);
    // storing the form errors in this state
    const [errors, setErrors] = useState({});
    // storing the fetching error of api
    const [error, setError] = useState(null);
    // storing the featched slots of that paticular trainer  
    const [slotOptions, setSlotOptions] = useState([]);
    // storing the session of according to price 
    const [sessionOptions, setSessionOptions] = useState([]);
    // storing the filled form data
    const [formData, setFormData] = useState({
        name: parseData && parseData.FullName || "",
        email: parseData && parseData.user || "",
        contact: parseData && parseData.phonenumber || "",
        trainer: "",
        availableTrainerId: "",
        availableBatchess: [],
        city: "",
        Course: { courseName },
        price: "",
    });
    // storing the details of the course which is fetched
    const [filteredCourse, setFilteredCourse] = useState(null);

    const [trainerDemo, setTrainerDemo] = useState([]);
    const [trainerId, setTrainerId] = useState(null);
    const [selectedTrainer, setSelectedTrainer] = useState("");

    //  sticky form submit
    const handleInputChange = (e, fromtype) => {
        const { id, value } = e.target;
        if (id === "contact") {
            if (!/^\d*$/.test(value) || value.length > 10) {
                return;
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));


        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: "",
        }));

    };
    // handle submit
    const handleSubmit = async (e) => {
        // console.log("firstsubmit")
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors);
            return;
        }

        try {
            const response = await REAPI.post(
                `/enroll/userEnrollment/${filteredCourse?.courseName}`,
                formData
            );
            setFormData({
                name: "",
                email: "",
                contact: "",
                city: "",
                trainer: "",
                slot: "",
                availableBatchess: "",
                Course: "",
            });
            navigate(`/course/${slugify(categoryName)}/${slugify(courseName)}/thankyou`);
            setErrors({});

            setTimeout(() => {
                navigate(-1);
            }, 3000);
        } catch (error) {
            // console.log("There was an error submitting the form:", error);
        }
    };
    // form validation
    const validateForm = (formData) => {
        let formErrors = {};

        // Validate name
        if (!formData.name) {
            formErrors.name = "Name is required";
            setErrors(formErrors);

            return false;
        } else if (formData.name.length < 3) {
            formErrors.name = "Name must be at least 3 characters long";
            setErrors(formErrors);

            return false;
        }

        if (!formData.email) {
            formErrors.email = "Email is required";
            setErrors(formErrors);

            return false;
        } else if (!/^[a-zA-Z0-9._%+-]{1,}@[a-zA-Z0-9.-]{1,}\.[a-zA-Z]{2,}$/.test(formData.email)) {
            formErrors.email = "Email address is invalid";
            setErrors(formErrors);

            return false;
        }
        // Validate contact number
        if (!formData.contact) {
            formErrors.contact = "Contact number is required";
            setErrors(formErrors);

            return false;
        } else if (formData.contact.length < 10) {
            formErrors.contact = "Contact number must be at least 10 digits";
            setErrors(formErrors);

            return false;
        }

        // Validate city
        if (!formData.city) {
            formErrors.city = "Please select a city";
            setErrors(formErrors);

            return false;
        }

        // Validate trainer selection
        if (!formData.trainer) {
            formErrors.trainer = "Please select a trainer";
            setErrors(formErrors);

            return false;
        }

        // Validate available batches
        if (!formData.availableBatchess) {
            formErrors.availableBatchess = "Please select available batches";
            setErrors(formErrors);

            return false;
        }

        // Validate slot selection
        if (!formData.slot) {
            formErrors.slot = "Please select a slot";
            setErrors(formErrors);

            return false;
        }

        // Set errors and return validation status
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return false;
        }

        setErrors({});
        return true;
    };
    // fetching trainer
    useEffect(() => {
        const fetchTrainer = async () => {
            try {
                const response = await REAPI.get(`/current/getTrainers/${courseName}`);
                const data = response.data;
                setTrainer(data);
            } catch (error) {
                // console.log("Trainer Loading error :", error.response);
            }
        };
        fetchTrainer();
    }, [courseName]);
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await REAPI.get(`/course/courses`);
                const coursesData = response.data;

                const currentCourse = coursesData.find(
                    (course) =>
                        course.categoryName?.toLowerCase().trim() ===
                        categoryName?.toLowerCase().trim() &&
                        course.courseName?.toLowerCase().trim() ===
                        courseName?.toLowerCase().trim()
                );

                setFilteredCourse(currentCourse || null);

                const relatedCoursefiltered = coursesData.filter(
                    (course) =>
                        course.categoryName?.toLowerCase() ===
                        categoryName?.toLowerCase() &&
                        course.courseName?.toLowerCase() !== courseName?.toLowerCase()
                );

                // setRelatedCourse(relatedCoursefiltered);
            } catch (error) {
                setError("Error fetching coursecs. Please try again later.");
            } finally {
                // setLoading(false);
            }
        };

        fetchCourses();
    }, [categoryName, courseName]);
    // fetching courses
    useEffect(() => {
        if (filteredCourse?.id && trainerId) {
            const fetchTrinerDemo = async () => {
                try {
                    const { data, status } = await REAPI.get(
                        `/current/getTrainer/${filteredCourse?.id}/${trainerId}`
                    );
                    if (status === 200) {
                        setTrainerDemo(data);
                    }
                } catch (error) {
                    setError(error);
                }
            };
            fetchTrinerDemo();
        }
    }, [filteredCourse?.id, trainerId]);

    // filtering the trainers from whole data
    // Transform the trainer data into options for the select dropdown
    const trainerOptions = (Array.isArray(trainer) && trainer.length > 0)
    ? trainer.map((trainerData) => ({
        value: trainerData.trainerDetails.trainerName,
        label: `${trainerData.trainerDetails.trainerName}`,
        id: trainerData.trainerDetails.availableTrainerId,
        courses: trainerData.courses.map((course) => ({
          courseDetails: course.courseDetails,
          batches: course.batches,
        })),
      }))
    : [];
  
    // this function is run when the user change the trainer the it set all the feilds related to that trainer
    const handleSelectChange = (selectedOption) => {
        const selectedTrainerData = trainer.find(
            (t) => t.trainerDetails.availableTrainerId === selectedOption.id
        );

        if (selectedTrainerData) {
            const availableBatches = selectedTrainerData.courses[0]?.batches?.map((batch) => ({
                label: `${batch.availableBatches[0]?.from} - ${batch.availableBatches[0]?.to}`,
                value: `${batch.availableBatches[0]?.from} - ${batch.availableBatches[0]?.to}`,
            })) || [];

            const sessionOptions = selectedTrainerData.courses[0]?.batches[0]?.sessions?.map(
                (session, index) => ({
                    id: index + 1,
                    label: `${session.name}`,
                    price: session.price,
                    feeType: selectedTrainerData.courses[0]?.batches[0]?.feetype || "Free",
                    value: session.price,
                })
            ) || [];

            setSlotOptions(availableBatches);
            setSessionOptions(sessionOptions);

            setFormData((prevData) => ({
                ...prevData,
                trainer: selectedOption.value,
                availableTrainerId: selectedOption.id,
            }));
            setSelectedTrainer(selectedOption);
        } else {
            setSlotOptions([]);
            setSessionOptions([]);
        }
    };



    // handeling the price change and store in formData
    const handleSlotChange = (e) => {
        const selectedSlot = JSON.parse(e.target.value);
        setFormData({
            ...formData,
            slot: [
                {
                    label: selectedSlot.label,
                    feeType: selectedSlot.feeType,
                    price: selectedSlot.price || "Free",
                }
            ]
        });
    };
    // handeling the batch change and store in formData
    const handleAvailabaleBatches = (selectedOption) => {
        setFormData({
            ...formData,
            availableBatchess: selectedOption.value,
        });
    };
    return (
        <div className="col-lg-4 mt-5 ">
            <div className="what_you_learn topMargin sticky-top">
                <div className="">
                    <h5>Fill out the form below and we'll respond promptly</h5>
                    <form className="course-enrollment-form formlistdesign">
                        <div className="form-group mb-3 align-items-center">
                            <label className="fw-500 text-dark me-3">Name {parseData && parseData.FullName ? " " : <span className="text-danger">*</span>} </label>
                            <input
                                type="text"
                                className={
                                    errors && errors.name
                                        ? "form-control input_bg_color error-input"
                                        : "form-control input_bg_color " || parseData && parseData.FullName ? "form-control input_bg_color" : "form-control input_bg_color  cursor-not-allowed"
                                }

                                disabled={parseData && parseData.FullName}
                                id="name"
                                placeholder="Enter your name"
                                value={formData.name}
                                onChange={(e) => handleInputChange(e, "NotPopUp")}
                                required
                                onKeyPress={(e) => {
                                    if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors && errors.name && (
                                    <p className="text-danger m-0 fs-xs">{errors.name}</p>
                                )}
                            </div>
                        </div>

                        <div className="form-group mb-3 align-items-center">
                            <label
                                className="fw-500 text-dark me-3"
                                style={{ minWidth: "100px" }}
                            >
                                Email {parseData && parseData.user ? " " : <span className="text-danger">*</span>}
                            </label>
                            <input
                                type="email"
                                className={
                                    errors && errors.email
                                        ? "form-control input_bg_color error-input"
                                        : "form-control input_bg_color " || parseData && parseData.user ? "form-control input_bg_color" : "form-control input_bg_color cursor-not-allowed"
                                }
                                id="email"
                                placeholder="Enter your email"
                                value={formData.email}
                                disabled={parseData && parseData.user}
                                onChange={(e) => handleInputChange(e, "NotPopUp")}
                                required
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors && errors.email && (
                                    <p className="text-danger m-0 fs-xs">
                                        {errors.email}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="form-group mb-3 align-items-center">
                            <label
                                className="fw-500 text-dark me-3"
                                style={{ minWidth: "100px" }}
                            >
                                Contact{" "} {parseData && parseData.phonenumber ? " " : <span className="text-danger">*</span>}
                            </label>
                            <input
                                type="tel"
                                className={
                                    errors && errors.contact
                                        ? "form-control input_bg_color error-input"
                                        : "form-control input_bg_color " || parseData && parseData.phonenumber ? "form-control input_bg_color" : "form-control input_bg_color cursor-not-allowed"
                                }
                                id="contact"
                                placeholder="Enter your contact number"
                                value={formData.contact}
                                disabled={parseData && parseData.phonenumber}
                                onChange={(e) => handleInputChange(e, "NotPopUp")}
                                required
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors && errors.contact && (
                                    <p className="text-danger m-0 fs-xs">
                                        {errors.contact}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="form-group mb-3 align-items-center">
                            <label
                                className="fw-500 text-dark me-3"
                                style={{ minWidth: "100px" }}
                            >
                                City<span className="text-danger">*</span>{" "}
                            </label>
                            <input
                                type="tel"
                                className={
                                    errors && errors.city
                                        ? "form-control input_bg_color error-input"
                                        : "form-control input_bg_color "
                                }
                                id="city"
                                placeholder="Enter your city"
                                value={formData.city}
                                onChange={(e) => handleInputChange(e, "NotPopUp")}
                                required
                                onKeyPress={(e) => {
                                    if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors && errors.city && (
                                    <p className="text-danger m-0 fs-xs">{errors.city}</p>
                                )}
                            </div>
                        </div>
                        <div className="form-group mb-3 align-items-center">
                            <label
                                className="fw-500 text-dark me-3"
                                style={{ minWidth: "100px" }}
                            >
                                Course
                            </label>
                            <input
                                type="tel"
                                className={
                                    errors && errors.Course
                                        ? "form-control input_bg_color error-input"
                                        : "form-control input_bg_color cursor-not-allowed"
                                }
                                id="city"
                                placeholder="Enter your city"
                                value={courseName}
                                required
                                disabled
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors && errors.Course && (
                                    <p className="text-danger m-0 fs-xs">
                                        {errors.Course}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="form-group mb-3 align-items-center">
                            <label className="fw-500 text-dark me-3">Trainer<span className="text-danger">*</span></label>
                            <Select
                                id="trainer"
                                className="fs-s bg-form text_color input_bg_color"
                                name="trainer"
                                options={trainerOptions}
                                value={
                                    trainerOptions.find(
                                        (option) => option.value === formData.trainer
                                    ) || null
                                }
                                onChange={handleSelectChange}
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors && errors.trainer && (
                                    <p className="text-danger m-0 fs-xs">{errors.trainer}</p>
                                )}
                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <label className="fw-500 text-dark">Price Details<span className="text-danger">*</span></label>
                            {sessionOptions.length > 0 ? (
                                <select
                                    className="form-select"
                                    name="slot"
                                    onChange={handleSlotChange}
                                    required
                                >
                                    <option value="" disabled selected>
                                        Select Price Option
                                    </option>
                                    {sessionOptions.map((option) => (
                                        <option
                                            key={option.id}
                                            value={JSON.stringify({
                                                label: option.label,
                                                price: option.price,
                                                feeType: option.feeType
                                            })}
                                        >
                                            {option.label}{" "}
                                            {option.feeType ? option.feeType : "Free Course"} -{" "}
                                            {option.price ? (
                                                <>
                                                    <LiaRupeeSignSolid />
                                                    {option.price}
                                                </>
                                            ) : (
                                                "Free Course"
                                            )}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                formData.trainer && (
                                    <p className="text-success">This is a Free Course</p>
                                )
                            )}
                        </div>

                        <div className="form-group mb-3 align-items-center">
                            <label className="fw-500 text-dark me-3">Available Batches<span className="text-danger">*</span></label>
                            <Select
                                className="fs-s bg-form text_color input_bg_color"
                                name="availableBatchess"
                                id="availableBatchess"
                                options={slotOptions}
                                onChange={handleAvailabaleBatches}
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors && errors.availableBatchess && (
                                    <p className="text-danger m-0 fs-xs">{errors.availableBatchess}</p>
                                )}
                            </div>
                        </div>
                        <div className=" mt-5">
                            <button
                                className="rbt-btn btn-sm w-100 sb-btn d-flex justify-content-center"
                                onClick={/*parseData?.token?handlepay:*/handleSubmit}
                            >
                                {/*parseData?.token?"pay now":"Signup to Enroll"*/}
                                Submit

                            </button>
                        </div>
                    </form>

                    {/* Social Share and Contact Section */}
                    <div className="social-share-wrapper mt-3 text-center">
                        <SocialLinksGroup />
                        <hr className="my-3" />
                        <div className="contact-with-us text-center">
                            <p>For details about the course</p>
                            <p className="rbtbadge-2 mt-2">
                                <CiPhone /> Call Us:{" "}
                                <Link to="tel:9133308354">
                                    <strong>9133308354</strong>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
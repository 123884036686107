import React from "react";
import Teksacademylogo from "../../../assets/images/mainlogo.svg";
import { IoMdArrowDropright, IoMdCall, IoMdMail } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { FaLocationDot, FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { IoLogoLinkedin } from "react-icons/io";
import { Link } from "react-router-dom";
import "../../../assets/css/Footer.css";
import TeksLogo from "../../../assets/images/Teks_Skill_HubLogo_05.png";

const Footer = () => {
  const footer_category = {
    category_1: {
      subLink1: [
        { name: "Arts", link: "/course/arts" },
        { name: "Dance and Music", link: "/course/dance-and-music" },
        { name: "Design Skills", link: "/course/design-skills" },
        { name: "Health and Wellness", link: "/course/health-and-wellness" },
        { name: "Hobbies and Life Style", link: "/course/hobbies-and-lifestyle" },
        { name: "Technology", link: "/course/technology" },
        { name: "Languages and Communication", link: "/course/languages-and-communication" },
      ],
    },
    category_2: {
      subLink1: [
        { name: "Technology", link: "/course/technology" },
        { name: "Languages and Communication", link: "/course/languages-and-communication" },
      ],
    },
  };
  return (
    <>
      <div className="container-fluid px-5">
        <hr className="mt-5 mb-3"></hr>
        <div className="footer-container mt-5">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <Link to="/">
                <img
                  src={TeksLogo}
                  alt="teksacademylogo"
                  className="w-75 h-auto"
                />
              </Link>
              <p className="fs-s pt-2 pb-1 text-black-50">
                Embrace creativity with India's No. 1 online skill enhancement platform in the ever-evolving tech, cultural, and artistic landscape. Join Teks Skill Hub to take your skills to the next level!.

              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <p className="fs-10 fw-700 ms-0">Category</p>
              <div className="row">
                <div className="col-12">
                  <ul>
                    {footer_category.category_1.subLink1.map((item, index) => (
                      <li key={index} className="fs-s pb-1">
                        <Link
                          className="underline-hover text-black-50"
                          to={item.link}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="col-md-5">
                  <ul>
                    {footer_category.category_2.subLink1.map((item, index) => (
                      <li key={index} className="fs-s pb-1">
                        <Link
                          className="underline-hover text-black-50"
                          to={item.link}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <p className="fs-10 fw-700 ms-0">Our Company</p>
              <ul>
                <li className="fs-s pb-1">
                  <Link className="underline-hover text-black-50" to="/about">
                    About Us
                  </Link>
                </li>
                <li className="fs-s pb-1">
                  <Link
                    className="underline-hover text-black-50"
                    to="/becomeatrainer"
                  >
                    Become a Teacher
                  </Link>
                </li>
                <li className="fs-s pb-1">
                  <Link
                    className="underline-hover text-black-50"
                    to="/contactus"
                  >
                    Contact Us
                  </Link>
                </li>
                {/* <li className="fs-s pb-1">
                <Link className="underline-hover text-black-50">Blog</Link>
              </li> */}
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-3">
              <p className="fs-10 fw-700 ms-0">Get Contact</p>
              <ul>
                <li className="fs-s pb-1">
                  <Link
                    to="tel:9133308354"
                    className="underline-hover text-black-50"
                  >
                    <IoMdCall className="teks_blue fs-3 pe-2" />
                    Phone: 9133308354
                  </Link>
                </li>
                <li className="fs-s pb-1">
                  <li className="fs-s pb-1">
                    <Link
                      to="mailto:support@teksskillhub.com"
                      className="underline-hover text-black-50"
                       target="_blank"
                    >
                      <IoMdMail className="teks_blue fs-3 pe-2" />
                      E-mail: support@teksskillhub.com
                    </Link>
                  </li>
                </li>
                <li className="fs-s pb-3">
                  <Link className="underline-hover text-black-50" to="https://maps.app.goo.gl/EAfSS7ULy2zsC4kh7" target="_blank">
                <FaLocationDot className="teks_blue fs-22 pe-2 me-1"/>
                    Location: H.no: 9- 1-127/4, Flat No: 501 & 502 5th Floor, Amsri Faust Building, S.D. Road, Secunderabad, Telangana 500025
                  </Link>
                </li>
                <Link to="https://www.instagram.com/teks_skillhub/" target="_blank">
                  {" "}
                  <RiInstagramFill className="me-2 fs-26 bg-apps" />
                </Link>

                <Link to="https://x.com/teksskillhub" target="_blank">
                  {" "}
                  <FaSquareXTwitter className="me-2 fs-26 bg-apps" />
                </Link>
                <Link to="https://www.facebook.com/teksskillhub" target="_blank">
                  {" "}
                  <FaFacebook className="me-2 fs-26 bg-apps" />
                </Link>
                <Link to="https://www.linkedin.com/company/teks-skillhub/" target="_blank">
                  <IoLogoLinkedin className="me-2 fs-26 bg-apps" />
                </Link>
              </ul>
            </div>
          </div>
          <hr className="footer-hr" />
          <div className="row d-flex justify-content-between mb-2">
            <div className="col-12 col-lg-6 fs-s text-secondary mb-2 mb-lg-0">
              Copyright © 2024 Kapil Knowledge Hub Private Limited. All Rights Reserved
            </div>
            <div className="col-12 col-lg-6 text-end fs-s text-secondary">
              <Link to="/TermsConditions">
                <span className="divider-item text-muted underline-hover text-black-50">
                  Terms & Conditions
                </span>
              </Link>
              <span className="divider-line">|</span>
              <Link to="/PrivacyPolicy">
                <span className="divider-item text-muted underline-hover text-black-50">
                  Privacy & policy
                </span>
              </Link>
              <span className="divider-line">|</span>
              <Link to="/Refund">
                <span className="divider-item text-muted underline-hover text-black-50">Refund policy</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react'
import { Modal } from "bootstrap"; // Import Bootstrap modal
import { FaRegStar, FaStar } from 'react-icons/fa';
import { REAPI } from '../../../../../serviceLayer/Interceptor';
// import checwk from "../../../../assets/images/check.png";
import check from "../../../../../assets/images/check.png"
import trainerDefault from "../../../../../assets/images/trainerdefault.png";

export const Rating = ({ courseName }) => {

    // states
    /*
        // this state is used to store the form input values of review form
    */
    const [formReview, setformReview] = useState({
        imageOfStudent: null,
        ratings: 0,
        studentName: "",
        reviewDescription: "",
    });
    // state to store the featched reviews
    const [reviewsRatings, setReviewRatings] = useState([]);

    // storing the reviews from the featched reviews and maped below 
    const [reviews, setReviews] = useState([]);

    // this is used to set how many reviews to display on screen
    const [visibleReviews, setVisibleReviews] = useState(5);

    // this is for the reviews loading status while featching
    const [loadingReviews, setLoadingReviews] = useState(true);

    // this is used while featching the reviews error storing
    const [errorReviews, setErrorReviews] = useState(null);
    const [refresh, setRefresh] = useState(false);

    //   error state for form 
    const [errors, setErrors] = useState({});
    // Total and average course featching error
    const [error, setError] = useState(null);



    // functions for getting the number of stars given in form
    const handleRating = (value) => {
        setformReview({ ...formReview, ratings: value });
    };
    // function for closing the thank you modal after submiting form
    const handleCloseReview = () => {
        window.location.reload();
    };

    // review error validation
    const validate = () => {
        const newErrors = {};
        if (!formReview.studentName) newErrors.studentName = "Name is required.";
        if (!formReview.reviewDescription)
            newErrors.reviewDescription = "Description is required.";
        if (formReview.ratings === 0) newErrors.ratings = "Rating is required.";
        return newErrors;
    };
    //   function for handle Change in inputs
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setformReview({
            ...formReview,
            [name]: type === "file" ? files[0] : value,
        });
        setErrors({...errors,[name]:""})
    };
    // function for submit review
    const handleReviewSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length) {
            setErrors(validationErrors);
            return;
        }

        const data = new FormData();
        data.append("imageOfStudent", formReview.imageOfStudent);
        data.append("ratings", formReview.ratings);
        data.append("studentName", formReview.studentName);
        data.append("reviewDescription", formReview.reviewDescription);
        data.append("courseName", courseName);

        try {
            const response = await REAPI.post(`/reviews/featuredreview`, data);

            if (response.status === 201) {
                setformReview({
                    imageOfStudent: null,
                    ratings: 0,
                    studentName: "",
                    reviewDescription: "",
                });
                setErrors({});
                const modalElement = document.getElementById("reviewSuccessModal");
                const modal = new Modal(modalElement);
                modal.show();
            } else {
                console.error("Error submitting review");
            }
        } catch (error) {
            console.error("Network error", error);
        }
    };

    //fetch featured Review api
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const encodedCourseName = encodeURIComponent(courseName); // Encode course name for safe URL use
                const response = await REAPI.get(
                    `/reviews/listReviews?filter={"courseName":"${encodedCourseName}"}`
                );
                setReviews(response.data.reviews);
            } catch (error) {
                setErrorReviews("Error fetching reviews. Please try again later.");
            } finally {
                setLoadingReviews(false);
            }
        };

        fetchReviews();
    }, [courseName, refresh]); // fetching the api when these change dected

    // it is used for getting the total and average of the course in the course name is changed
    useEffect(() => {
        if (courseName) {
            const fetchReviewPerCent = async () => {
                try {
                    const { data, status } = await REAPI.get(
                        `/reviews/totalandavgcourse/${courseName}`
                    );
                    if (status === 200) {
                        setReviewRatings(data);
                    }
                } catch (error) {
                    setError(error);
                }
            };
            fetchReviewPerCent();
        }
    }, [courseName]);

    // function to show the how many ratings we have to show 
    const handleToggleReviews = () => {
        if (visibleReviews >= reviews.length) {
            setVisibleReviews(5); // Reset to 5 when all reviews are shown
        } else {
            setVisibleReviews((prev) => Math.min(prev + 5, reviews.length)); // Show more reviews, up to the total count
        }
    };

    // calculating the secounds in days 
    const TIME_UNITS = [
        { label: "second", seconds: 1 },
        { label: "minute", seconds: 60 },
        { label: "hour", seconds: 3600 },
        { label: "day", seconds: 86400 },
    ];

    // function for getting the time and date of the review submitted 
    const timeAgo = (timestamp) => {
        const now = new Date();
        const reviewDate = new Date(timestamp);
        const secondsElapsed = Math.floor((now - reviewDate) / 1000);

        for (let i = TIME_UNITS.length - 1; i >= 0; i--) {
            const { label, seconds } = TIME_UNITS[i];
            const count = Math.floor(secondsElapsed / seconds);
            if (count > 0) {
                return `${count} ${label}${count !== 1 ? "s" : ""} ago`;
            }
        }

        return "just now";
    };
    return (
        <div>
            <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24 mb-3 ms-3">Rating</h4>
                <div className="col-lg-6 ps-3 mb-2 py-2">
                    <div className="rating-text">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <span
                                key={value}
                                onClick={() => handleRating(value)}
                                style={{ cursor: "pointer" }}
                            >
                                {value <= formReview.ratings ? (
                                    <FaStar className="review_color  ms-2 fs-24" />
                                ) : (
                                    <FaRegStar className="review_border  ms-2 fs-24" />
                                )}
                            </span>
                        ))}
                    </div>
                    {errors.ratings && (
                        <p className="text-danger fs-xs">{errors.ratings}</p>
                    )}
                </div>

                <form onSubmit={handleReviewSubmit}>
                    <div className="col-lg-8">
                        <div className="form-group mb-3 align-items-center">
                            <label className="form-label fs-s fw-medium black_300">
                                Name
                            </label>
                            <input
                                type="text"
                                name="studentName"
                                className={`form-control input_bg_color ${errors.studentName ? "error-input" : ""
                                    }`}
                                placeholder="Enter your name"
                                value={formReview.studentName}
                                onChange={handleChange}
                                required
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors.studentName && (
                                    <p className="text-danger m-0 fs-xs">
                                        {errors.studentName}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="form-label fs-s fw-medium black_300">
                            <label htmlFor="Textarea">Description</label>
                            <textarea
                                name="reviewDescription"
                                className={`form-control fs-s bg-form text_color input_bg_color ${errors.reviewDescription ? "is-invalid" : ""
                                    }`}
                                id="Textarea"
                                rows="3"
                                placeholder="Enter here ..."
                                onChange={handleChange}
                                value={formReview.reviewDescription}
                            ></textarea>
                            <div style={{ height: "8px" }}>
                                {errors.reviewDescription && (
                                    <p className="text-danger m-0 fs-xs">
                                        {errors.reviewDescription}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="mb-3">
                            <label
                                htmlFor="imageOfStudent"
                                className="form-label fs-s fw-medium black_300"
                            >
                                Image<span className="text-danger"></span>
                            </label>
                            <input
                                type="file"
                                name="imageOfStudent"
                                className={`form-control input_bg_color ${errors.imageOfStudent ? "error-input" : ""
                                    }`}
                                id="imageOfStudent"
                                onChange={handleChange}
                            />
                            <div className="response" style={{ height: "8px" }}>
                                {errors.imageOfStudent && (
                                    <p className="text-danger m-0 fs-xs">
                                        {errors.imageOfStudent}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="rbt-btn btn-sm ms-3">
                            Submit
                        </button>
                    </div>
                </form>
                <div
                    className="modal fade"
                    id="reviewSuccessModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content teks_bg_blue">
                            <div className="modal-body text-center">
                                <img
                                    src={check}
                                    className="img-fluid h-25 w-25"
                                    alt=""
                                />
                                <h4 className="text-white mt-3">Thank You!</h4>
                                <p className="text-white fs-13 fw-400">
                                    Your review has been submitted successfully.
                                </p>
                            </div>
                            <div className="d-flex justify-content-end me-2 mb-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-light"
                                    data-bs-dismiss="modal"
                                    onClick={handleCloseReview}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24">Review</h4>
                <hr />
                <div className="row align-items-center">
                    <div className="col-lg-3 col-sm-12">
                        <div className="rating-box">
                            <h5 className="fs-50 fw-700">
                                {reviewsRatings[0]?.averageRating || 0}
                            </h5>
                            <div>
                                {[...Array(5)].map((_, index) => {
                                    const averageRating =
                                        reviewsRatings[0]?.averageRating || 0;
                                    const filledStars = Math.floor(averageRating);
                                    const hasPartialStar = averageRating % 1 > 0;
                                    const fillPercentage = (averageRating % 1) * 100;

                                    if (index < filledStars) {
                                        return (
                                            <FaStar
                                                key={index}
                                                className="review_color fs-18"
                                            />
                                        );
                                    } else if (index === filledStars && hasPartialStar) {
                                        return (
                                            <div
                                                style={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                }}
                                                key={index}
                                            >
                                                <FaRegStar className="review_border fs-18" />
                                                <FaStar
                                                    className="review_color fs-18"
                                                    style={{
                                                        position: "absolute",
                                                        top: 5,
                                                        left: 0,
                                                        clipPath: `inset(0 ${100 - fillPercentage
                                                            }% 0 0)`,
                                                    }}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <FaRegStar
                                                key={index}
                                                className="review_border fs-18 fw-700"
                                            />
                                        );
                                    }
                                })}
                            </div>
                            <h6 className="review_color mt-2">Course Rating</h6>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12">
                        <div className="review-wrapper flex-column d-flex align-items">
                            {[5, 4, 3, 2, 1].map((star, idx) => (
                                <div className="single-progress-bar" key={star}>
                                    <div className="rating-text">
                                        {[...Array(5)].map((_, starIndex) =>
                                            starIndex < star ? (
                                                <FaStar
                                                    key={starIndex}
                                                    className="review_color fs-18"
                                                />
                                            ) : (
                                                <FaRegStar
                                                    key={starIndex}
                                                    className="review_border fs-18 fw-700"
                                                />
                                            )
                                        )}
                                    </div>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${reviewsRatings[0]?.[
                                                    `percentageRating${star}`
                                                ] || 0
                                                    }%`,
                                            }}
                                            role="progressbar"
                                            aria-valuenow={
                                                reviewsRatings[0]?.[
                                                `percentageRating${star}`
                                                ] || 0
                                            }
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        ></div>
                                    </div>
                                    <span className="value-text">
                                        {reviewsRatings[0]?.[`percentageRating${star}`] ||
                                            0}
                                        %
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24">Featured Reviews</h4>
                <hr />
                {reviews?.length > 0 ? (
                    reviews.slice(0, visibleReviews).map((review) => (
                        <div key={review.id}>
                            <div className="row text_center">
                                <div className="col-lg-2 responsive-w-25">
                                    <img
                                        src={review.imageOfStudent || trainerDefault}
                                        className="w-100 "
                                        alt={review.studentName}
                                    />
                                </div>
                                <div className="col-lg-10">
                                    <h5 className="fw-600">{review.studentName}</h5>
                                    <div>
                                        {[...Array(review.ratings)].map((_, i) => (
                                            <FaStar key={i} className="review_color fs-18" />
                                        ))}
                                    </div>
                                    <p className="mt-2">{review.reviewDescription}</p>
                                    <p className="text-muted fs-14">
                                        {timeAgo(review.createdAt)}
                                    </p>
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))
                ) : (
                    <p>No reviews available.</p>
                )}
                <div
                    className="notes-show-more-btn active"
                    onClick={handleToggleReviews}
                >
                    {visibleReviews >= reviews?.length ? "Show Less" : "Show More"}
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from "react";
import "../../../assets/css/Becomeinstrucor.css";
import girl from "../../../assets/images/girlimg.png";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { REAPI } from "../../../serviceLayer/Interceptor";
const BecameTeacher = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [course, setCourse] = useState([]);
  const coursesList = course.map((category) => ({
    label: category.courseName,
    value: category.courseName,
  }));

  const [error, setError] = useState({});
  const [Details, setDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    yearOfExperience: "",
    area: "",
    city: "",
    hours: "",
    Textarea: "",
    courses: [],
  });
  const [successmsg, setSuccessMsg] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstname" || name === "lastname") {
      const letterOnlyValue = value.replace(/[^a-zA-Z\s]/g, "");

      setDetails((prev) => ({
        ...prev,
        [name]: letterOnlyValue,
      }));
      let tempErrors = validateField(name, letterOnlyValue);
      setError((prevError) => ({
        ...prevError,
        [name]: tempErrors ? tempErrors[name] : "",
      }));
    } else if (name === "phonenumber") {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (numericValue.length <= 10) {
        setDetails((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
        let tempErrors = validateField(name, numericValue);
        setError((prevError) => ({
          ...prevError,
          [name]: tempErrors ? tempErrors[name] : "",
        }));
      }
    } else {
      setDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
      let tempErrors = validateField(name, value);
      setError((prevError) => ({
        ...prevError,
        [name]: tempErrors ? tempErrors[name] : "",
      }));
    }
  };

  const handleCourseAdd = (selectedOptions) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      courses: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
    // console.log(selectedOptions, "Selected courses");
    setError((prevError) => ({
      ...prevError,
      courses: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const tempErrors = validate();

    if (Object.keys(tempErrors).length === 0) {
      try {
        const { data, status } = await REAPI.post(`/trainer/trainers`, Details);

        // console.log(data, status, "sdhbjsvdhd");

        if (status === 201) {
          setDetails({
            firstname: "",
            lastname: "",
            email: "",
            phonenumber: "",
            yearOfExperience: "",
            area: "",
            city: "",
            hours: "",
            Textarea: "",
            courses: [],
          });
          setSuccessMsg("Registrated Successfully");
          navigate("/becomeatrainer/thankyou");
        }
      } catch (error) {
        const response = error.response;
        const data = response.data;
        console.error("bhdjjfdsjfvf", data);
        setError((prevError) => ({
          ...prevError,
          email: data.message,
        }));
      }
    } else {
      setError(tempErrors);
      // console.log("Validation errors:", tempErrors);
    }
  };

  useEffect(() => {
    const fetchCategories = async (setCategory) => {
      try {
        const response = await REAPI.get(`/course/courses`);
        const data = response.data;
        setCourse(data);
        // console.log(data, "dararaa");
        return data || [];
      } catch (error) {
        console.error("Failed to fetch categories:", error);
        throw error;
      }
    };

    fetchCategories();
  }, []);

  const validateField = (name, value) => {
    const tempErrors = {};
    switch (name) {
      case "firstname":
        if (!value.trim()) {
          tempErrors.firstname = "Trainer Name is required";
        } else if (value.trim().length < 3) {
          tempErrors.firstname =
            "Trainer Name must contain at least 3 characters";
        }
        break;

      case "lastname":
        if (!value.trim()) {
          tempErrors.lastname = "Trainer Name is required";
        } else if (value.trim().length < 3) {
          tempErrors.lastname =
            "Trainer Name must contain at least 3 characters";
        }
        break;

      case "email":
        if (!value) {
          tempErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          tempErrors.email = "Email address is invalid";
        }
        break;

      case "phonenumber":
        if (!value) {
          tempErrors.phonenumber = "Phone number is required";
        } else if (!/^\d+$/.test(value)) {
          tempErrors.phonenumber = "Mobile Number must contain only digits";
        } else if (value.length !== 10) {
          tempErrors.phonenumber = "Phone number must be exactly 10 digits";
        }
        break;

      case "yearOfExperience":
        if (!value) {
          tempErrors.yearOfExperience = "Experience is required";
        } else if (!/^\d+$/.test(value)) {
          tempErrors.yearOfExperience = "Year of Experience must be a number";
        }
        break;

      case "area":
        if (!value.trim()) {
          tempErrors.area = "Area is required";
        } else if (value.trim().length < 3) {
          tempErrors.area = "Area must contain at least 3 characters";
        }
        break;

      case "city":
        if (!value.trim()) {
          tempErrors.city = "City is required";
        } else if (value.trim().length < 3) {
          tempErrors.city = "City must contain at least 3 characters";
        }
        break;
      case "hours":
        if (!value) {
          tempErrors.hours = "Hours are required";
        } else if (!/^\d+$/.test(value)) {
          tempErrors.hours = "Hours must be a number";
        } else if (value < 0 || value > 24) {
          tempErrors.hours = "Invalid input";
        }
        break;
      case "courses":
        if (value.length === 0) {
          tempErrors.courses = "At least one course must be selected";
        }
        break;

      case "Textarea":
        if (!value.trim()) {
          tempErrors.Textarea = "Description Required";
        }
        break;

      default:
        break;
    }
    // console.log(tempErrors);
    return tempErrors;
  };

  // Validations
  const validate = () => {
    const tempErrors = {};
    const {
      firstname,
      lastname,
      email,
      phonenumber,
      yearOfExperience,
      area,
      city,
      courses,
      hours,
      Textarea,
    } = Details;
    if (!firstname.trim()) {
      tempErrors.firstname = "Trainer Name is required";
      return tempErrors;
    } else if (firstname.trim().length < 3) {
      tempErrors.firstname = "Trainer Name must contain at least 3 characters";
       return tempErrors;
    }
    if (!lastname) {
      tempErrors.lastname = "Trainer Name is required";
       return tempErrors;
    } else if (lastname.trim().length < 3) {
      tempErrors.lastname = "Trainer Name must contain at least 3 characters";
       return tempErrors;
    }

    if (!email) {
      tempErrors.email = "Email is required";
       return tempErrors;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email address is invalid";
       return tempErrors;
    }
    if (!phonenumber) {
      tempErrors.phonenumber = "Phone number is required";
       return tempErrors;
    } else if (!/^\d+$/.test(phonenumber)) {
      tempErrors.phonenumber = "Mobile Number must contain only digits";
       return tempErrors;
    } else if (phonenumber.length !== 10) {
      tempErrors.phonenumber = "Phone number must be exactly 10 digits";
       return tempErrors;
    }
    if (!yearOfExperience) {
      tempErrors.yearOfExperience = "Experience is required";
       return tempErrors;
    } else if (!/^\d+$/.test(yearOfExperience)) {
      tempErrors.yearOfExperience = "please enter Year of Experience";
       return tempErrors;
    } else if (yearOfExperience.length < 0) {
      tempErrors.hours = "Invalid input";
       return tempErrors;
    }
    

    if (!hours) {
      tempErrors.hours = "Hours are required";
       return tempErrors;
    } else if (!/^\d+$/.test(hours)) {
      tempErrors.hours = "Hours must be a number";
       return tempErrors;
    } else if (hours.length < 0 && hours.length > 24) {
      tempErrors.hours = "Invalid input";
       return tempErrors;
    }
    if (!city) {
      tempErrors.city = "City is required";
       return tempErrors;
    } else if (city.trim().length < 3) {
      tempErrors.city = "City must contain at least 3 characters";
       return tempErrors;
    } else if (!/^[a-zA-Z\s]+$/.test(city.trim())) {
      tempErrors.city = "City must contain only letters";
       return tempErrors;
    }
    if (!area) {
      tempErrors.area = "Area is required";
       return tempErrors;
    } else if (area.trim().length < 3) {
      tempErrors.area = "Area must contain at least 3 characters";
       return tempErrors;
    }
    if (courses.length === 0) {
      tempErrors.courses = "At least one course must be selected";
       return tempErrors;
    }
    if (!Textarea) {
      tempErrors.Textarea = "Description Required";
       return tempErrors;
    }
    setError(tempErrors);
    return tempErrors;
  };

  return (
    <div className="">
      <section className="container">
        <div className=" text-center">
          <div className="mb-3">
            <span className="instructor_header bg_orange text-light">
             Want To Become a Trainer ?
            </span>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <p className="fs-16">
                Ready to inspire and lead? Join us as a trainer and share your
                arts, languages, and skills expertise. Shape the future by
                teaching and mentoring students eager to learn from the best.
              </p>
            </div>
          </div>
        </div>
        <div className="row  my-4  mx-sm-3 align-items-center justify-content-center px-3 mx-md-5 g-4">
          <div className="col-lg-9 col-md-12 mx-0 shadow-lg px-lg-5 py-3">
            <h4 className="fw-600 text-center">Trainer's Registration</h4>
            <form className="">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label className="fw-500 text-dark me-3" htmlFor="firstname">
                    First Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.firstname ? "is-invalid" : ""
                    }`}
                    id="firstname"
                    placeholder="Enter First Name"
                    name="firstname"
                    pattern="[A-Za-z]+"
                    onChange={handleChange}
                    value={Details.firstname}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.firstname && (
                      <p className="text-danger m-0 fs-xs">
                        {error?.firstname}
                      </p>
                    )}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label className="fw-500 text-dark me-3" htmlFor="lastname">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.lastname ? "is-invalid" : ""
                    }`}
                    id="lastname"
                    placeholder="Enter Last Name"
                    name="lastname"
                    pattern="[A-Za-z]+"
                    onChange={handleChange}
                    value={Details.lastname}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.lastname && (
                      <p className="text-danger m-0 fs-xs">{error?.lastname}</p>
                    )}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label className="fw-500 text-dark me-3">Email<span className="text-danger">*</span></label>
                  <input
                    type="email"
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.email ? "is-invalid" : ""
                    }  `}
                    id="email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={handleChange}
                    value={Details.email}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.email && (
                      <p className="text-danger m-0 fs-xs">{error?.email}</p>
                    )}
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label
                    className="fw-500 text-dark me-3"
                    htmlFor="phonenumber"
                  >
                    Phone Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.phonenumber ? "is-invalid" : ""
                    }`}
                    id="phonenumber"
                    placeholder="Enter Phone Number"
                    name="phonenumber"
                    onChange={handleChange}
                    value={Details.phonenumber}
                    maxLength={10}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.phonenumber && (
                      <p className="text-danger m-0 fs-xs">
                        {error?.phonenumber}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label
                    className="fw-500 text-dark me-3"
                    htmlFor="yearOfExperience"
                  >
                    Experience (in years)<span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.yearOfExperience ? "is-invalid" : ""
                    }  `}
                    type="text"
                    name="yearOfExperience"
                    id="yearOfExperience"
                    value={Details.yearOfExperience}
                    placeholder="Enter Experience"
                    onChange={handleChange}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.yearOfExperience && (
                      <p className="text-danger m-0 fs-xs">
                        {error?.yearOfExperience}
                      </p>
                    )}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label className="fw-500 text-dark me-3" htmlFor="hours">
                    No. of hrs you can teach per day<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="hours"
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.hours ? "is-invalid" : ""
                    }  `}
                    id="hours"
                    placeholder="How many hours can you teach daily"
                    onChange={handleChange}
                    value={Details?.hours}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.hours && (
                      <p className="text-danger m-0 fs-xs">{error?.hours}</p>
                    )}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label className="fw-500 text-dark me-3">City<span className="text-danger">*</span></label>

                  <input
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.city ? "is-invalid" : ""
                    }  `}
                    type="text"
                    name="city"
                    id="city"
                    pattern="[A-Za-z]+"
                    value={Details.city}
                    placeholder="Enter City"
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.city && (
                      <p className="text-danger m-0 fs-xs">{error?.city}</p>
                    )}
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label className="fw-500 text-dark me-3">Area<span className="text-danger">*</span></label>
                  <input
                    className={`form-control fs-s bg-form text_color input_bg_color ${
                      error?.area ? "is-invalid" : ""
                    }  `}
                    type="text"
                    name="area"
                    id="area"
                    value={Details.area}
                    placeholder="Enter Area"
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <div style={{ height: "8px" }}>
                    {error && error?.area && (
                      <p className="text-danger m-0 fs-xs">{error?.area}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <label className="fw-500 text-dark me-3" htmlFor="courses">
                  Course<span className="text-danger">*</span>
                </label>
                <Select
                  className={` select_border fs-s bg-form text_color input_bg_color ${
                    error?.courses ? "select_is_invalid" : ""
                  }`}
                  isMulti
                  name="courses"
                  options={coursesList}
                  classNamePrefix="select"
                  value={coursesList.filter((option) =>
                    Details.courses.includes(option.value)
                  )}
                  styles={{
                    placeholder: (base) => ({
                      ...base,
                      fontSize: " 0.8125rem",
                      color: "#6c757d",
                    }),
                  }}
                  onChange={handleCourseAdd}
                />
                <div style={{ height: "8px" }}>
                  {error?.courses && (
                    <p className="text-danger m-0 fs-xs">{error.courses}</p>
                  )}
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="exampleFormControlTextarea1" htmlFor="Textarea" className="fw-500 text-dark me-3">
                  Description<span className="text-danger">*</span>
                </label>
                <textarea
                  className={`form-control fs-s bg-form text_color input_bg_color ${
                    error?.Textarea ? "is-invalid" : ""
                  }  `}
                  id="Textarea"
                  rows="3"
                  name="Textarea"
                  placeholder="Enter here ..."
                  onChange={handleChange}
                  value={Details?.Textarea}
                ></textarea>
                <div style={{ height: "8px" }}>
                  {error && error?.Textarea && (
                    <p className="text-danger m-0 fs-xs">{error?.Textarea}</p>
                  )}
                </div>
                
              </div>
              <div className="col-lg-2">
                {/* <p className="underline-hover text-black-50">
                  Terms & Condition
                </p> */}
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  class="btn-sm rbt-btn text-white mt-3"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
             
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BecameTeacher;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { REAPI } from "../../../serviceLayer/Interceptor";
import Select from "react-select";

const RequestDemo = () => {
  const { categoryName, courseName } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phonenumber: "",
    city: "",
    trainerName: "",
    course: courseName,
  });
  const [trainerDetails, setTrainerDetails] = useState(null); // Changed to store trainer details
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name) {
      formErrors.name = "Name is required";
    } else if (formData.name.length < 3) {
      formErrors.name = "Name must contain at least 3 characters";
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Invalid email address";
    }

    if (!formData.phonenumber) {
      formErrors.phonenumber = "Phone number is required";
    } else if (formData.phonenumber.length !== 10) {
      formErrors.phonenumber = "Phone number must be 10 digits";
    }

    if (!formData.city) {
      formErrors.city = "City is required";
    } else if (formData.city.length < 3) {
      formErrors.city = "City must contain at least 3 characters";
    }

    if (!formData.trainerName) {
      formErrors.trainerName = "Trainer is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await REAPI.post("/request/requestDemo", formData);
      // console.log("Form submitted successfully:", response.data);

      setFormData({
        name: "",
        email: "",
        phonenumber: "",
        trainerName: "",
        city: "",
        course: courseName,
      });
      setErrors({});

      const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
      if (closeButton) {
        closeButton.click();
        navigate(`/course/${categoryName}/${courseName}/thankyou`);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  // Prevent non-numeric input for phone number
  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const fetchTrainer = async () => {
      try {
        const originalCourse = decodeURIComponent(courseName.replace(/-/g, ' '))
        const response = await REAPI.get(`/current/getTrainers/${originalCourse}`);
        const data = response.data;
        // console.log(response.data,"traienrs Details")

        // Assuming the response follows the structure from your provided object
        if (data && data.length > 0) {
          // Assuming you want to display details for all trainers
          setTrainerDetails(data.map((trainer) => trainer.trainerDetails)); // Extracts the trainer details from all entries
        
          // Populate form data with the first trainer's details (or customize based on your needs)
          setFormData((prevData) => ({
            ...prevData,
            trainerName: data[0].trainerDetails.trainerName || "", // Set trainerName to the first trainer's name
          }));
        }
      } catch (error) {
        // console.log("Trainer loading error:", error.response);
      }
    };

    fetchTrainer();
  }, [courseName]);
  const trainerOptions = trainerDetails && trainerDetails.length > 0
  ? trainerDetails.map((trainer) => ({
      value: trainer.trainerName, // Access trainerName directly from trainer object
      label: trainer.trainerName, // Access trainerName directly from trainer object
      id: trainer.availableTrainerId, // Access availableTrainerId directly from trainer object
    }))
  : [];


  const handleSelectChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      trainerName: selectedOption.value,
    }));
  };

  return (
    <div>
      <button
        type="button"
        className="rbt-btn-org btn-sm me-2"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Contact Us
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Please Fill The Form
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label className="fw-500 text-dark me-3">Name</label>
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control input_bg_color"
                placeholder="Enter Name"
              />
              <div className="response" style={{ height: "8px" }}>
                {errors.name && (
                  <p className="text-danger m-0 fs-xs">{errors.name}</p>
                )}
              </div>

              <label className="fw-500 text-dark me-3 mt-2">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control input_bg_color"
                placeholder="Enter Email"
                required
              />
              <div className="response" style={{ height: "8px" }}>
                {errors.email && (
                  <p className="text-danger m-0 fs-xs">{errors.email}</p>
                )}
              </div>

              <label className="fw-500 text-dark me-3 mt-2">Phone Number</label>
              <input
                type="tel"
                name="phonenumber"
                value={formData.phonenumber}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                className="form-control input_bg_color"
                placeholder="Enter Phone Number"
              />
              <div className="response" style={{ height: "8px" }}>
                {errors.phonenumber && (
                  <p className="text-danger m-0 fs-xs">
                    {errors.phonenumber}
                  </p>
                )}
              </div>

              <label className="fw-500 text-dark me-3 mt-2">City</label>
              <input
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="form-control input_bg_color"
                placeholder="Enter City"
              />
              <div className="response" style={{ height: "8px" }}>
                {errors.city && (
                  <p className="text-danger m-0 fs-xs">{errors.city}</p>
                )}
              </div>

              <label className="fw-500 text-dark me-3 mt-2">Trainer</label>
              <Select
                id="trainerName"
                className="fs-s bg-form text_color input_bg_color"
                name="trainerName"
                options={trainerOptions}
                value={trainerOptions.find(
                  (option) => option.value === formData.trainerName
                )}
                onChange={handleSelectChange}
              />
              <div className="response" style={{ height: "8px" }}>
                {errors.trainerName && (
                  <p className="text-danger m-0 fs-xs">{errors.trainerName}</p>
                )}
              </div>

              <label className="fw-500 text-dark me-3 mt-2">Course</label>
              <input
                name="course"
                value={courseName}
                onChange={handleChange}
                disabled
                className="form-control input_bg_color cursor-not-allowed"
                placeholder="Enter Course"
              />
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button
                type="button"
                className="rbt-btn btn-sm"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDemo;

import React, { useState, useEffect } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import { REAPI } from "../../../serviceLayer/Interceptor";
import Yoga from "../../../assets/images/yoga.png";
import "../../../assets/css/CourseList.css";
import "../../../assets/css/newcourse.css";
import allCourse from "../../../assets/images/course.png";
const CourseLists1 = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Courses");
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [noResults, setNoResults] = useState(false); // Ensure this is defined
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [totalCourses, setTotalCourses] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const totalPages = Math.ceil(totalCourses / pageSize);

  const handleCategoryChange = (categoryName, index) => {
    setSelectedCategory(categoryName);
    setActiveIndex(index);
    setCourses([]); // Reset courses when category changes
    setPage(1); // Reset pagination
    setHasMore(true); // Reset "has more" when category changes
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setCourses([]); // Reset courses on search
    setPage(1); // Reset pagination on new search
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryResponse = await REAPI.get(`/category/getCategories`);
        const categoryData = categoryResponse.data;

        if (Array.isArray(categoryData) && categoryData.length > 0) {
          setCategories(categoryData);
          setActiveIndex(0); // Set default active index to the first category
        } else {
          console.error("Category data is not an array or is empty:", categoryData);
        }
      } catch (error) {
        console.error("Error fetching the categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchCourses = async (currentPage) => {
      setLoading(true);
      setError(null);

      try {
        let filter = {};
        if (selectedCategory && selectedCategory !== "All Courses") {
          filter = { categoryName: selectedCategory };
        }

        const filterString = JSON.stringify(filter);
        const courseResponse = await REAPI.get(
          `/course/listCourses?filter=${filterString}&search=${searchQuery}&page=${currentPage}&pageSize=${pageSize}`
        );

        const courseData = courseResponse.data.courses || [];
        const totalCourses = courseResponse.data.totalCourses || 0;

        if (Array.isArray(courseData)) {
          setCourses(courseData);
          setTotalCourses(totalCourses);
          setNoResults(courseData.length === 0); // Update noResults state
        } else {
          console.error("Course data is not an array:", courseData);
        }
      } catch (error) {
        console.error("Error fetching the courses:", error);
        setError("No Courses Available.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourses(page);
  }, [selectedCategory, searchQuery, page]); // Ensure this is only called when these values change
  const slugify = (text) => {
    return text
      .toString()
      .normalize("NFD") // Normalize accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .toLowerCase()
      .replace(/[^a-z0-9 +\-]/g, "") // Remove non-alphanumeric characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with one
  };

  return (
    <div className="p-0">
      <div className=" search-list text-end mb-3">
        <input
          type="text"
          className="search-inp me-lg-4 "
          placeholder="Search for..."
          name="search"
          value={searchQuery}
          onChange={handleSearchChange}
          required
        />
      </div>

      <div>
        <div className="row mx-1">
          <div className="col">
            <div className="d-flex">
              <div
                className="course-categories nav course-bottom mt-5 mb-0 col-3 nav-pills me-3 bg-white"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <h5 className="category-heading">Course Categories</h5>
                <button
                  className="nav-link nav-color w-100 course-btn border d-flex justify-content-between align-items-center"
                  onClick={() => handleCategoryChange("All Courses", -1)}
                  style={{
                    backgroundColor:
                      selectedCategory === "All Courses"
                        ? "#5165a5"
                        : "transparent",
                    color:
                      selectedCategory === "All Courses" ? "white" : "inherit",
                  }}
                >
                  <span className="span-container h-100 d-flex align-items-center gap-2">
                    <span className="h-100 bg-white p-1 round">
                      <img
                        src={allCourse}
                        className={`h-100`}
                        alt="allCourse"
                      />
                    </span>
                    <span className="span-container-span text-start">
                      All Courses
                    </span>
                  </span>
                  <MdArrowForwardIos className="text-white" />
                </button>
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      handleCategoryChange(category.category, index)
                    }
                    className={`nav-link nav-color w-100 course-btn border d-flex justify-content-between align-items-center ${selectedCategory === category.category ? "active" : ""
                      }`}
                    id={`v-pills-${category.category}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#v-pills-${category.category}`}
                    type="button"
                    role="tab"
                    aria-controls={`v-pills-${category.category}`}
                    aria-selected={selectedCategory === category.category}
                    style={{
                      backgroundColor:
                        selectedCategory === category.category
                          ? "#5165a5"
                          : "transparent",
                      color:
                        selectedCategory === category.category
                          ? "white"
                          : "inherit",
                    }}
                  >
                    <span
                      className={`span-container h-100 d-flex align-items-center gap-2`}
                    >
                      <span className="h-100 bg-white p-1 round">
                        <img
                          src={category.icon}
                          className={`h-100`}
                          alt={category.category}
                        />
                      </span>
                      <span className="span-container-span text-left">
                        {category?.category.charAt(0).toUpperCase() +
                          category?.category.slice(1)}
                      </span>
                    </span>
                    <MdArrowForwardIos className="text-white " />
                  </button>
                ))}
              </div>

              <div className="col-9  course-sides vh-100 ">
                <div className="row g-4 mt-2">
                  {error ? (
                    <p>{error}</p>
                  ) : noResults ? (
                    <p>No courses found for this category.</p>
                  ) : (
                    courses.map((course, index) => (
                      <div key={index} className="col-lg-4 mb-4">
                        <Link
                          to={`/course/${slugify(course?.category)}/${slugify(course.courseName)}`}
                        >
                          <div className="rbt-cards d-flex justify-content-between flex-column rbt-hover g-2">
                            <div className="row no-gutters mx-0 ">
                              <div className="col-12">
                                <img
                                  src={course.imageURL ? course.imageURL : Yoga}
                                  className="card-img img-fluid courselist-img"
                                  alt="Card Image"
                                />
                              </div>
                              <div className="col-12 mt-2">
                                <div className="card-bodys p-2">
                                  <h5 className="card-titles text-dark">
                                    {course?.courseName
                                      .charAt(0)
                                      .toUpperCase() +
                                      course?.courseName.slice(1)}
                                  </h5>
                                  <p className="card-texts text-muted">
                                    {course.courseHeading}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="py-3 mx-2">
                              <span className="view-span teks_blue fw-500">
                                Know More{" "}
                                <span className="fw-500 under-line1">→</span>
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLists1;

import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { REAPI } from '../../../../../serviceLayer/Interceptor';
import { Modal } from 'bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaIndianRupeeSign } from 'react-icons/fa6';

export const InstructorPopup = ({ trainer, parseData, courseName, trainerOptions, item, categoryName, filteredCourse, trainerIds }) => {
        // Slugify function
const slugify = (text) => {
    return text
      .toString()
      .normalize("NFD") // Normalize accented characters
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .toLowerCase()
      .replace(/[^a-z0-9 +\-]/g, "") // Remove non-alphanumeric characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with one
  };
    // console.log(item.trainerDetails.availableTrainerId,"item DAta")
    // console.log(courseName,"originalCourse")
    const originalCourse = decodeURIComponent(courseName.replace(/-/g, ' '));
    // console.log(originalCourse,"originalCourse")
    // const trainerIds = item.trainerDetails.availableTrainerId;
    const navigate = useNavigate();
    // batch timings state
    const [staticBatch, setStaticBatch] = useState("")
    const [errors, setErrors] = useState({});
    const [staticTrainer, setStaticTrainer] = useState("")
    const [trainerId, setTrainerId] = useState(null);
    const [slotOptions, setSlotOptions] = useState([]);
    // State to control the visibility of the popups
    const [isFirstPopupOpen, setIsFirstPopupOpen] = useState(false);
    const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false);
    const [error, setError] = useState(null);
    const [selectedTrainer, setSelectedTrainer] = useState("");

    const [slotOptionModal, setSlotOptionModal] = useState([]);
    const [sessionOptionsModal, setSessionOptionsModal] = useState([]);
    const [selectSlotModal, setSelectSlotModal] = useState("");
    const [staticInput, setStaticInput] = useState({
        name: "",
        email: "",
        contact: "",
        trainer: staticTrainer && staticTrainer || "",
        availableTrainerId: trainer?.trainerDetails?.availableTrainerId,
        availableBatchess: staticBatch && String(staticBatch) || "",
        city: "",
        Course: { originalCourse },
        price: "",
    });
    const [formData, setFormData] = useState({
        name: parseData && parseData.FullName || "",
        email: parseData && parseData.user || "",
        contact: parseData && parseData.phonenumber || "",
        trainer: staticTrainer && staticTrainer || "",
        availableTrainerId: "",
        availableBatchess: staticBatch && String(staticBatch) || [],
        city: "",
        Course: { originalCourse },
        price: "",
    });
    const [batchindex,setbatchIndex] = useState(null)
    const [courseindex,setcourseIndex] = useState(null)
    // Open the first popup
    const openFirstPopup = () => setIsFirstPopupOpen(true);

    // Close the first popup
    const closeFirstPopup = () => setIsFirstPopupOpen(false);

    // Open the second popup
    const openSecondPopup = (courseIndex,batchIndex,bathcFrom, batchTo, trainername) => {
        // console.log(trainername, "statocsdfsd")
        // console.log(batchIndex, "statocsdfsd1")
        // console.log(courseIndex, "statocsdfsd1")
        // console.log(trainerDemo, "statocsdfsd1")
        setbatchIndex(batchIndex)
        setcourseIndex(courseIndex)
        // console.log(trainerDemo, "trainerDamo")
        setStaticBatch(String(`${bathcFrom}-${batchTo}`))
        setStaticTrainer(trainername);
        // setStaticInput({ ...staticInput, availableBatchess: staticBatch });
        setIsFirstPopupOpen(false); // Close first popup when opening second
        setIsSecondPopupOpen(true);
        // console.log(staticInput, "handleAvailableBatchesPopup")
    };

    // useEffect(() => { setStaticInput({ ...staticInput, availableBatchess: staticBatch, trainer: staticTrainer }); console.log(staticInput, "uesEffiec") }, [staticTrainer])
    const [trainerDemo, setTrainerDemo] = useState([]);
    // useEffect(() => {
    //     if (item.trainerDetails.availableTrainerId && trainerId) {
    //         const fetchTrinerDemo = async () => {
    //             try {
    //                 const { data, status } = await REAPI.get(
    //                     `/current/getTrainer/${item.trainerDetails.availableTrainerId}/${trainerId}`
    //                 );
    //                 if (status === 200) {
    //                     setTrainerDemo(data);
    //                 }
    //             } catch (error) {
    //                 setError(error);
    //             }
    //         };
    //         fetchTrinerDemo();
    //     }
    // }, [item.trainerDetails.availableTrainerId, trainerId]);

    if (trainerDemo) {
        // console.log(trainerDemo, "demoTrainer");
    }
    const handleSelectChangePopup = (selectedOption) => {
        if (!selectedOption || !selectedOption.batchId) {
            console.error('Invalid selectedOption passed:', selectedOption);
            return;
        }

        // Now selectedOption is a batch, we need to find the associated trainer and course
        const trainerWithCourses = trainer.find(
            (train) => train.courses.some(course =>
                course.batches.some(batch => batch.batchId === selectedOption.batchId)
            )
        );

        if (!trainerWithCourses) {
            console.error('Trainer not found for selectedOption.batchId');
            return;
        }

        const availableTrainerCourses = trainerWithCourses.courses;

        // console.log('Selected Trainer Courses:', availableTrainerCourses);

        let sessionOptions = [];

        // Loop through the courses and batches to create session options
        availableTrainerCourses.forEach((course) => {
            course.batches.forEach((batch) => {
                if (batch.batchId === selectedOption.batchId && batch.sessions.length > 0) {
                    batch.sessions.forEach((session, index) => {
                        sessionOptions.push({
                            id: index + 1,
                            label: session.name,
                            price: session.price,
                            originalPrice: session.originalPrice || null,
                            feeType: batch.feetype || "Session",
                            value: session.price,
                        });
                    });
                }
            });
        });

        // console.log('Session Options:', sessionOptions);

        setSessionOptionsModal(sessionOptions); // Update session options modal
    };






    const handleAvailableBatchesPopup = (selectedOption) => {
        // console.log("handleAvailableBatchesPopup")
        if (staticBatch) {
            setStaticInput({ ...staticInput, availableBatchess: staticBatch });
        }
        else {
            setStaticInput({ ...staticInput, availableBatchess: selectedOption.value });
        }

    };
    const handleSlotChangePopUp = (e) => {
        const selectedSlot = JSON.parse(e.target.value);
        setStaticInput({
            ...staticInput,
            slot: [
                {
                    label: selectedSlot.label, // Set the label
                    feeType: selectedSlot.feeType, // Set the feeType
                    price: selectedSlot.price || "Free", // Set the price or "Free" if not provided
                }
            ]
        });
        setErrorsPopUp(prev => ({
            ...prev,
            slot: ""
        }))
    };
    const handlePopUpChange = (e, fromtypes) => {
        const { id, value } = e.target;
        if (id === "contact") {
            if (!/^\d*$/.test(value) || value.length > 10) {
                return;
            }
        }

        setStaticInput((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        setErrorsPopUp((prevErrors) => ({
            ...prevErrors,
            [id]: "",
        }));
    };
    const handleSubmitPopup = async (e) => {
        const trainerID = trainer[0]?.trainerDetails?.availableTrainerId;
        // console.log(trainerID, "sourseName")
        // console.log(String(staticBatch), "Staticbatch")
        // console.log(staticTrainer, "Staticbatch")
        e.preventDefault();
        setStaticInput(prev => ({
            ...prev,
            availableBatchess: String(staticBatch),
            trainer: staticTrainer,
            availableTrainerId: trainerID,
            Course: { originalCourse }
        }))
        // console.log(staticInput, "static inputs")
        const isValid = validateFormpopup(staticInput);
        if (!isValid) {
            return; // Exit if there are validation errors
        }

        try {
            const originalCourse = decodeURIComponent(courseName.replace(/-/g, ' '));
            // console.log(originalCourse,"orig")
            // console.log(courseName,"orig")
            const response = await REAPI.post(
                `/enroll/userEnrollment/${filteredCourse?.courseName}`,
                staticInput
            );
            setStaticInput({
                name: "",
                email: "",
                contact: "",
                city: "",
                trainer: "",
                slot: "",
                availableBatchess: "",
                Course: "",
            });
            setErrorsPopUp({});
            navigate(`/course/${categoryName}/${slugify(originalCourse)}/thankyou`);
            setTimeout(() => {
                navigate(-1);
            }, 3000);
            const modalElement = document.getElementById("exampleModalToggle2");
            const modalInstance = Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide(); // Close modal programmatically
            }
            document.querySelectorAll(".modal-backdrop").forEach((el) => el.remove());

        } catch (error) {
            // console.log("There was an error submitting the form:", error);
        }
    };
    const validateFormpopup = (staticInput) => {
        let formErrorspopup = {};

        if (!staticInput.name) {
            formErrorspopup.name = "Name is required";
        } else if (staticInput.name.length < 3) {
            formErrorspopup.name = "Name must be at least 3 characters long";
        }
        if (!staticInput.email) {
            formErrorspopup.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(staticInput.email)) {
            formErrorspopup.email = "Invalid email format";
        }

        if (!staticInput.contact) {
            formErrorspopup.contact = "Contact number is required";
        } else if (staticInput.contact.length < 10) {
            formErrorspopup.contact = "Contact number must be at least 10 digits";
        }

        if (!staticInput.city) {
            formErrorspopup.city = "City is required";
        }

        if (!staticInput.availableBatchess) {
            formErrorspopup.availableBatchess = "Please select available batches";
        }

        if (!staticInput.slot) {
            formErrorspopup.slot = "price selection is required";
        }
        // console.log("Validation Errors: ", formErrorspopup);
        setErrorsPopUp(formErrorspopup);

        // Return true if there are no errors
        return Object.keys(formErrorspopup).length === 0;
    };

    // Close both popups
    const closeBothPopups = () => {
        setErrorsPopUp({})
        setStaticInput({
            name: "",
            email: "",
            contact: "",
            city: "",
            trainer: "",
            slot: "",
            availableBatchess: "",
            Course: "",
        });
        setIsFirstPopupOpen(false);
        setIsSecondPopupOpen(false);
    };
    const [errorsPopUp, setErrorsPopUp] = useState({});

    // Helper function to check if the URL is valid
    const isValidURL = (string) => {
        try {
            new URL(string); // Tries to create a new URL object
            return true; // Valid URL
        } catch (err) {
            return false; // Invalid URL
        }
    };

    const fetchTrainerDetails = async () => {
        // console.log(trainerIds, "trainers ids")
        try {
            const { data, status } = await REAPI.get(
                `/current/getTrainer/${trainerIds}`
            );
            if (status === 200) {
                setTrainerDemo(data);
            }
        } catch (error) {
            setError(error);
        }
    }

    return (
        <div className="text-center mb-0 ">
            <div className="app text-start ">
                {/* <h1>Nested Popups Example</h1> */}

                {/* Button to open the first popup */}
                <span className="teks_bg_blue fs-13  py-1 px-2 text-center rounded mb-4 " style={{ cursor: "pointer", width: "155px" }} onClick={() => {
                    openFirstPopup();
                    setTrainerId(item?.availableTrainerId);
                    fetchTrainerDetails()
                }}>View Demo</span>

                {/* First Level Popup */}
                {trainerDemo && isFirstPopupOpen && (
                    <div className="popup">
                        <div className="popup-content col-11 col-lg-8 col-md-10">
                            <div className="d-flex border-bottom mb-2 justify-content-between">
                                <h2 className="fs-5">Trainer Demo</h2>
                                <button type="button" className="btn-close" onClick={closeFirstPopup}></button>
                            </div>
                            <div className="">
                                <div className="row">
                                    {/* Video on the left side */}
                                    <div className="col-md-6">
                                        {/* Check if demoLink is valid */}
                                        {trainerDemo?.courses?.[0]?.batches?.[0]?.demoLink && isValidURL(trainerDemo.courses[0].batches[0].demoLink) ? (
                                            <iframe
                                                key={trainerDemo.courses[0].batches[0].batchId}
                                                className="video"
                                                title="Video player"
                                                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                                                width="100%"
                                                height="100%"
                                                src={`https://www.youtube.com/embed/${new URL(trainerDemo.courses[0].batches[0].demoLink).searchParams.get("v") ||
                                                    new URL(trainerDemo.courses[0].batches[0].demoLink).pathname.split("/").pop()}`}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        ) : (
                                            <p>Demo link not available or invalid</p>
                                        )}

                                    </div>

                                    {/* Description on the right side */}
                                    <div className="col-md-6">
                                        <p className="fw-600">Description :</p>
                                        {/* Show the batch description, if available, otherwise fall back to trainer's general description */}
                                        <p>
                                            {trainerDemo?.courses?.[0]?.batches?.[0]?.trainerCourseDescription ||
                                                trainerDemo?.trainerDetails?.trainerCourseDescription ||
                                                "No course description available"}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="card-body bg_white">
                                    <div className="table-responsive table-scroll table-container table-card border-0 bg_white">
                                        <table className="bg_white table table-centered align-middle table-nowrap equal-cell-table table-hover text-start">
                                            <thead className="tableheading">
                                                <tr>
                                                    <th scope="col" className="fs-13 lh-xs fw-600">Timing</th>
                                                    <th scope="col" className="fs-13 lh-xs fw-600">Days</th>
                                                    <th scope="col" className="fs-13 lh-xs fw-600">Price</th>
                                                    <th scope="col" className="fs-13 lh-xs fw-600">Enroll Now</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    trainerDemo?.courses?.map((course, courseIndex) => {
                                                        // Check if the course matches the courseName from params
                                                        // console.log(course?.courseDetails?.courseName ,"course1")
                                                        if (course?.courseDetails?.courseName.toLowerCase() === originalCourse) {
                                                            return course?.batches?.map((batch, batchIndex) =>
                                                                batch?.availableBatches?.map((availableBatch, index) => (
                                                                    <tr key={`${courseIndex}-${batchIndex}-${index}`}>

                                                                        {/* Timing */}
                                                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                                            {availableBatch?.from} - {availableBatch?.to}
                                                                        </td>

                                                                        {/* Days */}
                                                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                                            {batch?.days?.join(", ") || "No days available"}
                                                                        </td>

                                                                        {/* Price */}
                                                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                                            {batch?.courseType === "Paid" ? (
                                                                                batch?.sessions?.[0] && (
                                                                                    <div key={0}>
                                                                                        ₹{batch.sessions[0]?.price}
                                                                                        {batch?.feetype && ` / ${batch?.feetype.toLowerCase()}`}
                                                                                    </div>
                                                                                )
                                                                            ) : (
                                                                                <span>Free Course</span>
                                                                            )}
                                                                        </td>

                                                                        {/* Enroll Now */}
                                                                        <td className="fs-13 black_300 fw-500 lh-xs bg_light">
                                                                            <span
                                                                                className="teks_bg_blue py-1 px-2 rounded d-flex justify-content-center align-items-center flex-wrap"
                                                                                onClick={() => {
                                                                                    if (batch?.availableBatches?.length > 0) {
                                                                                        handleSelectChangePopup(batch);
                                                                                        openSecondPopup(
                                                                                            courseIndex,
                                                                                            batchIndex,
                                                                                            availableBatch?.from,
                                                                                            availableBatch?.to,
                                                                                            trainerDemo.trainerDetails.trainerName
                                                                                        );
                                                                                    } else {
                                                                                        alert('No available timings for this batch.');
                                                                                    }
                                                                                }}
                                                                                style={{ cursor: "pointer" }}
                                                                            >
                                                                                Enroll Now
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                        return null; // If the course doesn't match, return null so it won't render anything
                                                    })
                                                }


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}




                {/* Second Level Popup */}
                {isSecondPopupOpen && (
                    <div className="popup">
                        <div className="popup-content col-10 col-lg-8 col-md-10">
                            <div className="d-flex border-bottom mb-2 justify-content-between">
                                <h2 className="fs-5">Enroll Now</h2>
                                <button type="button" className="btn-close" onClick={closeBothPopups}></button>
                            </div>
                            <form className="course-enrollment-form formlistdesign">
                                <div className="row">
                                    {/* Name Input */}
                                    <div className="col-lg-4 form-group mb-3 align-items-center">
                                        <label className="fw-500 text-dark me-3">
                                            Name<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control input_bg_color ${errorsPopUp?.name ? "error-input" : ""}`}
                                            id="name"
                                            placeholder="Enter your name"
                                            value={staticInput.name || ""}
                                            onChange={(e) => handlePopUpChange(e, "popup")}
                                            required
                                        />
                                        <div className="response" style={{ height: "8px" }}>
                                            {errorsPopUp?.name && <p className="text-danger m-0 fs-xs">{errorsPopUp.name}</p>}
                                        </div>
                                    </div>

                                    {/* Email Input */}
                                    <div className="col-lg-4 form-group mb-3 align-items-center">
                                        <label className="fw-500 text-dark me-3" style={{ minWidth: "100px" }}>
                                            Email<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="email"
                                            className={`form-control input_bg_color ${errorsPopUp?.email ? "error-input" : ""}`}
                                            id="email"
                                            placeholder="Enter your email"
                                            value={staticInput.email || ""}
                                            onChange={(e) => handlePopUpChange(e)}
                                            required
                                        />
                                        <div className="response" style={{ height: "8px" }}>
                                            {errorsPopUp?.email && <p className="text-danger m-0 fs-xs">{errorsPopUp.email}</p>}
                                        </div>
                                    </div>

                                    {/* Contact Input */}
                                    <div className="col-lg-4 form-group mb-3 align-items-center">
                                        <label className="fw-500 text-dark me-3" style={{ minWidth: "100px" }}>
                                            Contact<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="tel"
                                            className={`form-control input_bg_color ${errorsPopUp?.contact ? "error-input" : ""}`}
                                            id="contact"
                                            placeholder="Enter your contact number"
                                            value={staticInput.contact || ""}
                                            onChange={(e) => handlePopUpChange(e)}
                                            required
                                        />
                                        <div className="response" style={{ height: "8px" }}>
                                            {errorsPopUp?.contact && <p className="text-danger m-0 fs-xs">{errorsPopUp.contact}</p>}
                                        </div>
                                    </div>

                                    {/* City Input */}
                                    <div className="col-lg-4 form-group mb-3 align-items-center">
                                        <label className="fw-500 text-dark me-3" style={{ minWidth: "100px" }}>
                                            City<span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control input_bg_color ${errorsPopUp?.city ? "error-input" : ""}`}
                                            id="city"
                                            placeholder="Enter your city"
                                            value={staticInput.city || ""}
                                            onChange={(e) => handlePopUpChange(e)}
                                            required
                                        />
                                        <div className="response" style={{ height: "8px" }}>
                                            {errorsPopUp?.city && <p className="text-danger m-0 fs-xs">{errorsPopUp.city}</p>}
                                        </div>
                                    </div>

                                    {/* Course Input */}
                                    <div className="col-lg-4 form-group mb-3 align-items-center">
                                        <label className="fw-500 text-dark me-3" style={{ minWidth: "100px" }}>
                                            Course
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control input_bg_color cursor-not-allowed ${errors?.Course ? "error-input" : ""}`}
                                            id="course"
                                            placeholder="Course name"
                                            value={originalCourse}
                                            required
                                            disabled
                                        />
                                        <div className="response" style={{ height: "8px" }}>
                                            {errors?.Course && <p className="text-danger m-0 fs-xs">{errors.Course}</p>}
                                        </div>
                                    </div>

                                    {/* Trainer Input */}
                                    <div className="col-lg-4 form-group mb-3 align-items-center">
                                        <label className="fw-500 text-dark me-3" style={{ minWidth: "100px" }}>
                                            Trainer
                                        </label>
                                        {staticTrainer ? (
                                            <input
                                                type="text"
                                                value={staticTrainer}
                                                className="form-control input_bg_color cursor-not-allowed"
                                                disabled
                                            />
                                        ) : (
                                            <Select
                                                id="trainer"
                                                className="fs-s bg-form text_color input_bg_color"
                                                name="trainer"
                                                options={trainerOptions}
                                                value={selectedTrainer ? { label: selectedTrainer.trainerName, value: selectedTrainer.id } : null} // Ensure value is an object
                                                isDisabled={!selectedTrainer}  // Disable Select if no trainer is selected
                                                onChange={(selectedOption) => handleSelectChangePopup(selectedOption)} // Pass the entire object
                                                placeholder={selectedTrainer?.trainerName || 'Select Trainer'}
                                            />
                                        )}
                                        <div className="response" style={{ height: "8px" }}>
                                            {errors?.trainer && <p className="text-danger m-0 fs-xs">{errors.trainer}</p>}
                                        </div>
                                    </div>


                                    {/* Price Details */}
                                    {/* Price Details */}
                                    <div className="col-lg-4 form-group mb-3">
                                        <label className="fw-500 text-dark">
                                            Price Details<span className="text-danger">*</span>
                                        </label>

                                        {trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.feetype === 'Session' ? (
                                            // If feetype is 'Session', skip the 0th index and render the rest
                                            trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.sessions?.length > 1 ? (
                                                <select
                                                    className={`form-select input_bg_color ${errorsPopUp?.slot ? "error-input" : ""}`}
                                                    name="slot"
                                                    onChange={handleSlotChangePopUp}
                                                    required
                                                >
                                                    <option value="" disabled selected>
                                                        Select Price
                                                    </option>
                                                    {/* Skipping the first session option */}
                                                    {trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.sessions.slice(1).map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={JSON.stringify({
                                                                label: option.name,
                                                                price: option.price,  // Show the price here
                                                                feeType: 'Session',  // Static as we know it's session
                                                            })}
                                                        >
                                                            {`${option.name} (Session) - ₹${option.price}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <p>No price options available</p>
                                            )
                                        ) : trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.feetype === 'Month'||trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.feetype === 'Course' ? (
                                            // If feetype is 'Month', show all options without prices
                                            trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.sessions?.length > 0 ? (
                                                <select
                                                    className={`form-select input_bg_color ${errorsPopUp?.slot ? "error-input" : ""}`}
                                                    name="slot"
                                                    onChange={handleSlotChangePopUp}
                                                    required
                                                >
                                                    <option value="" disabled selected>
                                                        Select Price
                                                    </option>
                                                    {/* For Month, show all sessions but no price */}
                                                    {trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.sessions.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={JSON.stringify({
                                                                label: option.name,
                                                                price: option.price,  // Show "N/A" for month
                                                                feeType: trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.feetype,  // Static as we know it's month
                                                            })}
                                                        >
                                                            {`${option.name} (${trainerDemo?.courses?.[courseindex]?.batches?.[batchindex]?.feetype}) - ₹${option.price}`} {/* No price shown */}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <p>No price options available</p>
                                            )
                                        ) : (
                                            // If no valid feetype (either session or month), show default message
                                            <p>No price options available</p>
                                        )}

                                        <div className="response" style={{ height: "8px" }}>
                                            {errorsPopUp?.slot && <p className="text-danger m-0 fs-xs">{errorsPopUp.slot}</p>}
                                        </div>
                                    </div>



                                    {/* Available Batches */}
                                    <div className="col-lg-4 form-group mb-3 align-items-center">
                                        <label className="fw-500 text-dark me-3" style={{ minWidth: "100px" }}>
                                            Available Batches
                                        </label>
                                        {staticBatch ? (
                                            <input
                                                type="text"
                                                value={staticBatch}
                                                className="form-control input_bg_color cursor-not-allowed"
                                                disabled
                                            />
                                        ) : (
                                            <Select
                                                className="fs-s bg-form text_color input_bg_color"
                                                name="availableBatchess"
                                                options={slotOptionModal}
                                                onChange={handleAvailableBatchesPopup}
                                            />
                                        )}
                                        <div className="response" style={{ height: "8px" }}>
                                            {errors?.availableBatchess && (
                                                <p className="text-danger m-0 fs-xs">{errors.availableBatchess}</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* Submit Button */}
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <span
                                            className="teks_bg_blue mx-auto py-1 px-5 rounded mb-4 fw-600"
                                            onClick={handleSubmitPopup}
                                            style={{cursor:"pointer"}}
                                        >
                                            Submit
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}



            </div>
        </div>
    )
}
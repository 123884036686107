import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./componentLayer/components/footer/Footer";
import Navbar from "./componentLayer/components/navbar/Navbar";

import CourseListsRoutes from "./routerLayer/courselists/CourseListsRoutes";
import ContactUsRoutes from "./routerLayer/contactUsRoutes/ContactUsRoutes";
import BecameTeacherRouter from "./routerLayer/bacameteacherRouter/BecameTeacherRouter";
import AboutRoutes from "./routerLayer/aboutRouter/AboutRoutes";
import ThankYou from "./utils/thankyoupage/ThankYou";
import PrivacyPolicy from "./componentLayer/pages/termconditions/PrivacyPolicy";
import Terms from "./componentLayer/pages/termconditions/Terms";
import Refund from "./componentLayer/pages/termconditions/Refund";
import { lazy, Suspense } from "react";
import LoadingScreen from "./utils/loading/LoadingScreen";
import Error404 from "./componentLayer/pages/error/Error404"; // Error 404 component
import { LoginRoutes } from "./routerLayer/LoginRoutes/LoginRoutes";
import CoursesRoutes from "./routerLayer/courses/CoursesRoutes";

const HomeRoutes = lazy(() => import("./routerLayer/home/HomeRoutes"));

function App() {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        {/* Lazy load HomeRoutes */}
        <Route
          path="/"
          element={
            <Suspense fallback={<LoadingScreen />}>
              <HomeRoutes />
            </Suspense>
          }
        />
        
        {/* Define other routes */}
        {/* <Route path="/courselist/*" element={<CourseListsRoutes />} /> */}
        <Route path="/course/*" element={<CoursesRoutes />} />
        <Route path="/contactus/*" element={<ContactUsRoutes />} />
        <Route path="/about/*" element={<AboutRoutes />} />
        <Route path="/becomeatrainer/*" element={<BecameTeacherRouter />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsConditions" element={<Terms />} />
        <Route path="/Refund" element={<Refund />} />
        <Route path="/*" element={<LoginRoutes />} />

        {/* Catch-all route for 404 page */}
        <Route path="*" element={<Error404 />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;






// import { Route, Routes } from "react-router-dom";
// import "./App.css";
// import Footer from "./componentLayer/components/footer/Footer";
// import Navbar from "./componentLayer/components/navbar/Navbar";
// import React, { Suspense, lazy } from "react";
// import LoadingScreen from "./utils/loading/LoadingScreen";

// // Lazy loading components
// const HomeRoutes = lazy(() => import("./routerLayer/home/HomeRoutes"));
// const CourseListsRoutes = lazy(() => import("./routerLayer/courselists/CourseListsRoutes"));
// const ContactUsRoutes = lazy(() => import("./routerLayer/contactUsRoutes/ContactUsRoutes"));
// const BecameTeacherRouter = lazy(() => import("./routerLayer/BecameTeacherRouter/BecameTeacherRouter"));
// const AboutRoutes = lazy(() => import("./routerLayer/aboutRouter/AboutRoutes"));
// const ThankYou = lazy(() => import("./utils/thankyoupage/ThankYou"));
// const PrivacyPolicy = lazy(() => import("./componentLayer/pages/termconditions/PrivacyPolicy"));
// const Terms = lazy(() => import("./componentLayer/pages/termconditions/Terms"));
// const Refund = lazy(() => import("./componentLayer/pages/termconditions/Refund"));

// function App() {
//   return (
//     <div className="App">
//       <Navbar />
//       <Suspense fallback={<div> <LoadingScreen /></div>}>
//         <Routes>
//           <Route path="/*" element={<HomeRoutes />} />
//           <Route path="/courselist/*" element={<CourseListsRoutes />} />
//           <Route path="/contactus/*" element={<ContactUsRoutes />} />
//           <Route path="/about/*" element={<AboutRoutes />} />
//           <Route path="/becomeatrainer/*" element={<BecameTeacherRouter />} />
//           <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
//           <Route path="/TermsConditions" element={<Terms />} />
//           <Route path="/Refund" element={<Refund />} />
//         </Routes>
//       </Suspense>
//       <Footer />
//     </div>
//   );
// }

// export default App;

// import { Route, Routes } from "react-router-dom";
// import "./App.css";
// import Footer from "./componentLayer/components/footer/Footer";
// import Navbar from "./componentLayer/components/navbar/Navbar";
// import HomeRoutes from "./routerLayer/home/HomeRoutes";
// import CourseListsRoutes from "./routerLayer/courselists/CourseListsRoutes";
// import ContactUsRoutes from "./routerLayer/contactUsRoutes/ContactUsRoutes";
// import BecameTeacherRouter from "./routerLayer/bacameteacherRouter/becomeatrainerRouter";
// import AboutRoutes from "./routerLayer/aboutRouter/AboutRoutes";
// import ThankYou from "./utils/thankyoupage/ThankYou";
// import PrivacyPolicy from "./componentLayer/pages/termconditions/PrivacyPolicy";
// import Terms from "./componentLayer/pages/termconditions/Terms";
// import Refund from "./componentLayer/pages/termconditions/Refund";

// function App() {
//   return (
//     <div className="App">
//       <Navbar />
//       <Routes>
//         <Route path="/*" element={<HomeRoutes />} />
//         <Route path="/courselist/*" element={<CourseListsRoutes />} />
//         <Route path="/contactus/*" element={<ContactUsRoutes />} />
//         <Route path="/about/*" element={<AboutRoutes />} /> 
//         <Route path="/becomeatrainer/*" element={<BecameTeacherRouter />} />  
//         <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
//         <Route path="/TermsConditions" element={<Terms />} />
//         <Route path="/Refund" element={<Refund />} />
//        </Routes>
//       <Footer />
//     </div>
//   );
// }
// export default App;

import React from 'react';
// import "../../../assets/css/Page6.css";
import "../../../assets/css/Page7.css";

const cards = [
  {
    imgSrc: "https://teksacademy.com/assets/img/branch_images/testimonials_3.png",
    authorImgSrc: "https://histudy-nextjs.vercel.app/_next/image?url=%2Fimages%2Fcourse%2Fcourse-01.jpg&w=750&q=75",
    authorName: "- Sai Ram",
    text: " I learn revit bim course... It's a excellent training centre... The teacher are will train then give bast performance... "
  },
  {
    imgSrc: "https://teksacademy.com/assets/img/branch_images/testimonials_12.png",
    authorImgSrc: "https://histudy-nextjs.vercel.app/_next/image?url=%2Fimages%2Fcourse%2Fcourse-01.jpg&w=750&q=75",
    authorName: " - Abhi ",
    text: " Great start to learn and explore trending courses. Thank you to all the experts who shared their expertise "
  },
  {
    imgSrc: "https://teksacademy.com/assets/img/branch_images/testimonials_15.png",
    authorImgSrc: "https://histudy-nextjs.vercel.app/_next/image?url=%2Fimages%2Fcourse%2Fcourse-01.jpg&w=750&q=75",
    authorName: " - Gayatri Manda ",
    text: " I have completed my aws course in Skill Hub at Secunderabad branch .I’m so blessed to learn my course here I also experienced IT company . "
  },
  {
    imgSrc: "https://teksacademy.com/assets/img/branch_images/testimonials_17.png",
    
    authorName: " - Phani Prasad ",
    text: " SAP VLSI trainer is very experienced and explains from ground level to top notch level. With realtime projects and IEP.They have good, experienced faculty. ."
  },
  // Add more card objects here
];
const cards2 = [
  {
    imgSrc: "	https://teksacademy.com/assets/img/branch_images/testimonials_11.png",
    
    authorName: "- Tela Ravinder",
    text: " Iam from python fullstack I gained knowledge from Skill Hub through raghavendra sir dilsuknagar branch.."
  },
  {
    imgSrc: "https://teksacademy.com/assets/img/branch_images/testimonials_46.png",
    authorImgSrc: "https://histudy-nextjs.vercel.app/_next/image?url=%2Fimages%2Fcourse%2Fcourse-01.jpg&w=750&q=75",
    authorName: " - Krishna ",
    text: " It was good, very effective learning pedology.... trainers are well trained. Thank you skillHub. ."
  },
  {
    imgSrc: "https://teksacademy.com/assets/img/branch_images/testimonials_47.png",
   
    authorName: " - Gnanendra Reddy ",
    text: " “I could be happier with my trainer and my course...very trustworthy. ...Very satisfied. ."
  },
  {
    imgSrc: "https://teksacademy.com/assets/img/branch_images/testimonials_48.png",
    authorImgSrc: "https://histudy-nextjs.vercel.app/_next/image?url=%2Fimages%2Fcourse%2Fcourse-01.jpg&w=750&q=75",
    authorName: " - Aditya Guturu ",
    text: " Hi this Aditya, I am a student from Skill Hub. And it's good to gain knowledge. And I have learnt python fullstack course from this. ."
  },
  // Add more card objects here
];

const Page6 = () => {
  return (
    <div className="marquee-wrapper mb-5">
      <div className="col-lg-12 p-5">
        <div className="section-title text-center">
          <span className="badge2 mt-0">
           Education For Everyone
          </span>
          <h2 className="title fw-bold mt-4">
          From Passion to Proficiency <br /> Skills for Everyone!
          </h2>
        </div>
      </div>
      <div className="marquee-content gap-3">
        {cards.concat(cards).map((card, index) => (
          <div
            key={index}
            className={`cards d-flex justify-content-center align-items-center py-3 px-5 rounded shadow flex-column col-lg-4 col-md-4 col-12 ${index % 2 === 0 ? 'card-color-1' : 'card-color-2'}`}
          >
            <img
              src={card.imgSrc}
              className="card-img-tops rounded-circle"
              alt="Google logo"
            />
            <div className="card-bodys" >
              <p className="card-text">{card.text}</p>
              <div className="rbt-author-meta mb--10 d-flex">
                <div className="rbt-avater">
                 
                </div>
                <div className="rbt-author-info ms-3">
                  <p>{card.authorName}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="marquee-content-2 gap-3">
        {cards2.concat(cards).map((card, index) => (
          <div
            key={index}
            className={`card2 d-flex justify-content-center align-items-center py-3 px-5 rounded shadow flex-column col-lg-4 col-md-4 col-12 ${index % 2 === 0 ? 'card-color-1' : 'card-color-2'}`}
          >
            <img
             
              src={card.imgSrc}
              className="card-img-tops rounded-circle"
              alt="Google logo"
            />
            <div className="card-bodys" >
              <p className="card-text">{card.text}</p>
              <div className="rbt-author-meta mb--10 d-flex">
                <div className="rbt-avater">
                 
                </div>
                <div className="rbt-author-info ms-3">
                  <p >{card.authorName}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Page6;

import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"; // Using NavLink and Link from react-router-dom
import "../../../assets/css/Navbar.css";
import "../../../assets/css/Page9.css";
import TeksLogo from "../../../assets/images/Teks_Skill_HubLogo_05.png";
import { Auth } from "../Context/authContext/AuthContext";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const LocalStorage = localStorage.getItem("data");
  const parseData = JSON.parse(LocalStorage);

  // Access logout function from AuthContext
  const { logout } = useContext(Auth);

  const [isOpen, setIsOpen] = useState(false);

  // Toggle the navigation menu
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Close the menu when a link is clicked
  const handleLinkClick = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location]); 

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white p-3 bg-body-tertiary rounded">
      <div className="container-fluid">
        <Link to="/" onClick={() => { navigate("/"); }}>
          <img src={TeksLogo} className="nav-imgs" alt="Logo" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={handleToggle}
          aria-controls="navbarSupportedContent"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 tab-bar">
            <li className="nav-item me-4 mt-2 cat-des">
              <NavLink to="/" className="tab" end>
                Home
              </NavLink>
            </li>
            <li className="nav-item me-4 mt-2 cat-des">
              <NavLink to="/about" className="tab">
                About Us
              </NavLink>
            </li>
            <li className="nav-item me-4 mt-2 cat-des">
              <NavLink to="/course" className="tab">
                Courses
              </NavLink>
            </li>
            <li className="nav-item me-4 mt-2 cat-des">
              <NavLink to="/becomeatrainer" className="tab">
                Become A Trainer
              </NavLink>
            </li>
            <li className="nav-item me-3 mt-2 cat-des">
              <NavLink to="/contactus" className="tab">
                Contact Us
              </NavLink>
            </li>
          </ul>

          {/* Conditional Rendering based on localStorage */}
          {/* {!parseData ? (
            <button className="blogpost-animated-button mt-1">
              <svg viewBox="0 0 24 24" className="arr-2" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
              </svg>
              <span className="text ps-3 pe-1">
                <Link to="/Signup" className="text-dark">
                  Enroll Now
                </Link>
              </span>
              <span className="blogpost-circle"></span>
              <svg viewBox="0 0 24 24" className="arr-1" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path>
              </svg>
            </button>
          ) : (
            <button onClick={logout} className="blogpost-animated-button mt-1">
              Logout
            </button>
          )} */}
            <Link to="/contactus" className="text-dark">
          <button className="blogpost-animated-button mt-1">
              Enroll Now
          </button>
            </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

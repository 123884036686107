import React from 'react'
import { CiFacebook, CiInstagram, CiLinkedin, CiPhone, CiTwitter } from 'react-icons/ci'
import { Link } from 'react-router-dom'

export const SocialLinksGroup = () => {
    return (
        
            <div className="rbt-post-share d-flex align-items-center justify-content-center">
                <ul className="social-icon list-inline mb-3">
                    <li className="list-inline-item ">
                        <Link to="https://www.facebook.com/">
                            <CiFacebook />
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="https://www.twitter.com">
                            <CiTwitter />
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="https://www.instagram.com/">
                            <CiInstagram />
                        </Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="https://www.linkedin.com/">
                            <CiLinkedin />
                        </Link>
                    </li>
                </ul>
            </div>
            
        )
}

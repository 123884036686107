import React, { useEffect, useState } from 'react'

export const Requirements = ({ filteredCourse, cleanedHTML }) => {
    const [showMore, setShowMore] = useState(false);
    const handleToggle = () => {
        setShowMore(!showMore);
    };
    function removeInlineStyles(html) {
        return html.replace(/style="[^"]*"/g, "");
    }
    const [beforeList, afterList] = cleanedHTML.split(/<ul>|<\/ul>/);
    const listItems =
        cleanedHTML
            .match(/<li>(.*?)<\/li>/g)
            ?.map((item) => item.replace(/<\/?li>/g, "")) || [];

    const initialItems = listItems.slice(0, 0);

    return (
        <>
            <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24 ">What you'll learn</h4>
                <div className="banner_pragraph_color fs-18">                    <div
                        dangerouslySetInnerHTML={{ __html: beforeList.trim() }}
                    />
                </div>
                <ul className="notes-list-style-1 banner_pragraph_color fs-18">
                    {(showMore ? listItems : initialItems).map((item, index) => (
                        <li key={index}>
                            <i className="tick_mark fs-18 banner_pragraph_color"></i>
                            {item}
                        </li>
                    ))}
                </ul>
                {/* Show More / Show Less button */}
                <div className="notes-show-more-btn" onClick={handleToggle}>
                    {showMore ? "Show Less" : "Show More"}
                </div>
            </div>
            <div className="what_you_learn mt-4">
                <div className="row">
                    <div className="col-lg-12">
                        <h4 className="fw-700 fs-24">Requirements</h4>
                        <hr></hr>
                        <ul className="notes-list-style-1">
                            {filteredCourse.requirement &&
                                filteredCourse.requirement.length > 0 ? (
                                filteredCourse.requirement.map((req, index) => (
                                    <li key={index} className="banner_pragraph_color fs-18">
                                        <i className="tick_mark fs-18"></i>
                                        {req.name}
                                    </li>
                                ))
                            ) : (
                                <li className="banner_pragraph_color fs-18">
                                    <i className="tick_mark fs-18"></i>
                                    No specific requirements.
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <h4 className="fw-700 fs-24">Description</h4>
                        <hr></hr>
                        <ul className="notes-list-style-1">
                            {filteredCourse.description &&
                                filteredCourse.description.length > 0 ? (
                                filteredCourse.description.map((desc, index) => (
                                    <li
                                        key={index}
                                        className="banner_pragraph_color fs-18"
                                    >
                                        <i className="tick_mark fs-18"></i>
                                        {desc.name}
                                    </li>
                                ))
                            ) : (
                                <li className="banner_pragraph_color fs-18">
                                    <i className="tick_mark fs-18"></i>
                                    No specific description available.
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

            </div>
        </>
    )
}

import { Modal } from "bootstrap"; // Import Bootstrap modal
import '../../../../assets/css/singlecourse.css'
import React, { useEffect, useState } from "react";
// import {
//   CiFacebook,
//   CiInstagram,
//   CiLinkedin,
//   CiPhone,
//   CiTwitter,
// } from "react-icons/ci";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
// import { LiaRupeeSignSolid } from "react-icons/lia";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import check from "../../../../assets/images/check.png";
import trainerDefault from "../../../../assets/images/trainerdefault.png";
import { REAPI } from "../../../../serviceLayer/Interceptor";
import LoadingScreen from "../../../../utils/loading/LoadingScreen";
import RequestDemo from "../../courselists/RequestDemo";
import { Stickyform } from "./singlecourselandingpage/Stickyform";
import { Rating } from "./singlecourselandingpage/Rating";
import { RelatedCourses } from "./singlecourselandingpage/RelatedCourses";
import { Requirements } from "./singlecourselandingpage/Requirements";
import { InstructorPopup } from "./singlecourselandingpage/InstructorPopup";

const SingleCourseLandingPage = () => {

  // Retrieve user data from localStorage (for conditional rendering)
  const LocalStorage = localStorage.getItem("data");
  const parseData = JSON.parse(LocalStorage)
  // console.log(parseData&& parseData,"localDAta")

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formReview, setformReview] = useState({
    imageOfStudent: null,
    ratings: 0,
    studentName: "",
    reviewDescription: "",
  });
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [errorReviews, setErrorReviews] = useState(null);

  const validate = () => {
    const newErrors = {};
    if (!formReview.studentName) newErrors.studentName = "Name is required.";
    if (!formReview.reviewDescription)
      newErrors.reviewDescription = "Description is required.";
    if (formReview.ratings === 0) newErrors.ratings = "Rating is required.";
    return newErrors;
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }

    const data = new FormData();
    data.append("imageOfStudent", formReview.imageOfStudent);
    data.append("ratings", formReview.ratings);
    data.append("studentName", formReview.studentName);
    data.append("reviewDescription", formReview.reviewDescription);
    data.append("courseName", courseName);

    try {
      const response = await REAPI.post(`/reviews/featuredreview`, data);

      if (response.status === 201) {
        setformReview({
          imageOfStudent: null,
          ratings: 0,
          studentName: "",
          reviewDescription: "",
        });
        setErrors({});
        const modalElement = document.getElementById("reviewSuccessModal");
        const modal = new Modal(modalElement);
        modal.show();
      } else {
        console.error("Error submitting review");
      }
    } catch (error) {
      console.error("Network error", error);
    }
  };

  const { categoryName, courseName } = useParams();
  const originalCourse = decodeURIComponent(courseName.replace(/-/g, ' '));
  const originalCategory = decodeURIComponent(categoryName.replace(/-/g, ' '));
  const [filteredCourse, setFilteredCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [relatedCourse, setRelatedCourse] = useState(null);
  const [reviewsRatings, setReviewRatings] = useState([]);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    if (courseName) {
      const fetchReviewPerCent = async () => {
        try {
          const originalCourse = decodeURIComponent(courseName.replace(/-/g, ' '));
          const { data, status } = await REAPI.get(
            `/reviews/totalandavgcourse/${originalCourse}`
          );
          if (status === 200) {
            setReviewRatings(data);
          }
        } catch (error) {
          setError(error);
        }
      };
      fetchReviewPerCent();
    }
  }, [courseName]);

  const [trainerDemo, setTrainerDemo] = useState([]);
  const [trainerId, setTrainerId] = useState(null);

  useEffect(() => {
    if (filteredCourse?.id && trainerId) {
      const fetchTrinerDemo = async () => {
        try {
          const { data, status } = await REAPI.get(
            `/current/getTrainer/${filteredCourse?.id}/${trainerId}`
          );
          if (status === 200) {
            setTrainerDemo(data);
          }
        } catch (error) {
          setError(error);
        }
      };
      fetchTrinerDemo();
    }
  }, [filteredCourse?.id, trainerId]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        // console.log("Fetching courses...");
        const response = await REAPI.get(`/course/courses`);
        const coursesData = response.data;
  
        // Log the API response
        // console.log('API Response:', coursesData);
  
        const originalCourse = decodeURIComponent(courseName.replace(/-/g, ' '));
        const originalCategory = decodeURIComponent(categoryName.replace(/-/g, ' '));
  
        // Log original course and category
        // console.log('Original Course:', originalCourse);
        // console.log('Original Category:', originalCategory);
  
        // Find the current course
        const currentCourse = coursesData.find(
          (course) =>
            course.categoryName?.toLowerCase().trim() === originalCategory?.toLowerCase().trim() &&
            course.courseName?.toLowerCase().trim() === originalCourse?.toLowerCase().trim()
        );
  
        // Log the found course
        // console.log('Found Course:', currentCourse);
  
        setFilteredCourse(currentCourse || null);
  
        // Filter related courses
        const relatedCoursefiltered = coursesData.filter(
          (course) =>
            course.categoryName?.toLowerCase() === originalCategory?.toLowerCase() &&
            course.courseName?.toLowerCase() !== originalCourse?.toLowerCase()
        );
  
        // Log related courses
        // console.log('Related Courses:', relatedCoursefiltered);
        setRelatedCourse(relatedCoursefiltered);
  
      } catch (error) {
        console.error("Error fetching courses:", error);
        setError("Error fetching courses. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchCourses();
  }, [categoryName, courseName]);
  

  //fetch featured Review api
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const encodedCourseName = encodeURIComponent(courseName); // Encode course name for safe URL use
        const response = await REAPI.get(
          `/reviews/listReviews?filter={"courseName":"${encodedCourseName}"}`
        );
        setReviews(response.data.reviews);
      } catch (error) {
        setErrorReviews("Error fetching reviews. Please try again later.");
      } finally {
        setLoadingReviews(false);
      }
    };

    fetchReviews();
  }, [courseName, refresh]);


  const [trainer, setTrainer] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [selectSlot, setSelectSlot] = useState("");
  const [selectSlotModal, setSelectSlotModal] = useState("");

  useEffect(() => {
    const fetchTrainer = async () => {
      // console.log("fetchingtrainer")
      try {
        
        const originalCourse = decodeURIComponent(courseName.replace(/-/g, ' '));
        const response = await REAPI.get(`/current/getTrainers/${originalCourse}`);
        const data = response.data;
        // console.log(response.data, "reaopnse data")
        setTrainer(data);
      } catch (error) {
        // console.log("Trainer Loading error :", error.response);
      }
    };
    fetchTrainer();
  }, [courseName]);

  const trainerOptions = (trainer && Array.isArray(trainer) && trainer.length > 0)
  ? trainer.map((trainers) => ({
      value: trainers.trainerName,
      label: trainers.trainerName,
      id: trainers.id,
    }))
  : [];


  const [formData, setFormData] = useState({
    name: parseData && parseData.FullName || "",
    email: parseData && parseData.user || "",
    contact: parseData && parseData.phonenumber || "",
    trainer: "",
    availableTrainerId: "",
    availableBatchess: [],
    city: "",
    Course: { originalCourse },
    price: "",
  });

  const [staticInput, setStaticInput] = useState({
    name: "",
    email: "",
    contact: "",
    trainer: "",
    availableTrainerId: "",
    availableBatchess: [],
    city: "",
    Course: { originalCourse },
    price: "",
  });

  useEffect(() => {
    const fetchSlots = async () => {
      if (selectedTrainer) {
        try {
          const availableSlot = trainer.find(
            (item) => item.id === selectedTrainer.id
          );
          setSelectSlotModal(availableSlot.availableBatchess);
          setSelectSlot(availableSlot.availableBatchess);
          setStaticInput({
            ...staticInput,
            availableBatchess: availableSlot.availableBatchess,
          });
          setFormData({
            ...formData,
            availableBatchess: availableSlot.availableBatchess,
          });
        } catch (error) {
          // console.log(error, "slots error");
        }
      }
    };
    fetchSlots();
  }, [selectedTrainer]);

  // const [selectedPrice, setSelectedPrice] = useState(null);
  const [errors, setErrors] = useState({});

  // payment function

  // const handlepay = async (e) => {
  //   e.preventDefault();
  //   const validationErrors = validateForm(formData);
  //   if (Object.keys(validationErrors).length > 0) {
  //     setError(validationErrors);
  //     return;
  //   }
  //   try {
  //     // navigate(`/authentication/Signup`);
  //     // const response = await REAPI.post(
  //     //   `/enroll/userEnrollment/${filteredCourse?.courseName}`,
  //     //   formData
  //     // );
  //     setFormData({
  //       name: "",
  //       email: "",
  //       contact: "",
  //       city: "",
  //       trainer: "",
  //       slot: "",
  //       availableBatchess: "",
  //       Course: "",
  //     });
  //     setErrors({});


  //     // setTimeout(() => {
  //     //   navigate(-1);
  //     // }, 3000);
  //   } catch (error) {
  //     // console.log("payment Error",error)
  //   }
  // }


  if (loading)
    return (
      <p>
        <LoadingScreen />
      </p>
    );
  if (error) return <p>{error}</p>;
  if (!filteredCourse) return <p>Course not found</p>;

  //code start for wht you will learn i am getting from the api
  function removeInlineStyles(html) {
    return html.replace(/style="[^"]*"/g, "");
  }

  const cleanedHTML = removeInlineStyles(filteredCourse.textWhatToLearn);

  return (
    <div>
      <div className="container-fluid p-0">
        <div className="course-bg-banner">
          <div className="banner-courselanding">
            <div className="padding_css">
              <p className="fs-s banner_pragraph_color text-white">
                Home <IoIosArrowForward /> Course
              </p>

              <div className="row">
                <div className="col-lg-7 banner_text">
                  <h2 className="fs-44 fw-700 text-white">
                    {filteredCourse?.courseName}
                  </h2>
                  <p className="text-white">{filteredCourse.courseHeading}</p>
                  <div className="d-flex align-items-center flex-wrap">
                    <p className="star_Css">
                      {Array.from({ length: 5 }, (_, index) => {
                        const fullStar =
                          index + 1 <= Math.floor(filteredCourse?.courseRating);
                        const halfStar =
                          index + 1 >
                          Math.floor(filteredCourse?.courseRating) &&
                          index < filteredCourse?.courseRating;

                        if (fullStar) {
                          return (
                            <FaStar
                              key={index}
                              className="mb-1  review_color"
                            />
                          );
                        } else if (halfStar) {
                          return (
                            <FaStarHalfAlt
                              key={index}
                              className="mb-1  review_color"
                            />
                          );
                        } else {
                          return (
                            <FaRegStar
                              key={index}
                              className="mb-1  review_color"
                            />
                          );
                        }
                      })}
                    </p>
                    <p className="fs-m fw-500 rating_css text-white">
                      <span className="">
                        {filteredCourse.ratingsCount > 0
                          ? filteredCourse.ratingsCount
                          : 0}{" "}
                        Ratings
                      </span>
                    </p>
                    <p className="fs-m students_css fw-500 text-white">
                      {filteredCourse.studentsCount > 0
                        ? filteredCourse.studentsCount
                        : 0}{" "}
                      Students
                    </p>
                  </div>
                  <div className="mt-4">
                    <RequestDemo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --- */}
        <div className="container">
          <div className="row mt-3">
            <div className="col-lg-8">
              <div className="what_you_learn mt-4">
                <h4 className="fw-700 fs-24">Instructor</h4>
                <div className="row">
                  {trainer.length > 0 ? (
                    trainer.map((item, index) => (
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-4" key={index}>
                        <div className="trainer_box shadow-sm flex-column align-items-center justify-content-between">
                          <div className="text-center">
                            <div className="photo_bg">
                              <img
                                src={item.trainerDetails.trainerImage ? item.trainerDetails.trainerImage : trainerDefault}
                                className="rounded w-100 h-100"
                                alt="trainer"
                              />
                            </div>
                            <h5 className="mt-0">
                              {item.trainerDetails.trainerName
                                .split(' ') // Split the string by spaces
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
                                .join(' ')} {/* Join all words back with a space */}
                            </h5>
                          </div>
                          <div className="row">
                            {/* First Column */}
                            <div className="col-md-12 text-start">
                              <ul className="ms-4 mb-0">
                                {/* Timings Section */}
                                <li className="banner_pragraph_color d-flex fs-s fw-500 mt-3">
                                  <span className="fw-700 text-dark">Timing</span>
                                  <span className="ms-3">:</span>
                                  <span className="px-3">
                                    {/* Access the first batch */}
                                    {item?.courses?.[0]?.batches[0]?.availableBatches?.length > 0 ? (
                                      item?.courses?.[0]?.batches[0]?.availableBatches
                                        .slice(0, 2) // Only get the first two available timings
                                        .map((availableBatch, availableIndex) => (
                                          <div key={availableIndex}>
                                            <span>
                                              {availableBatch?.from} - {availableBatch?.to}
                                            </span>
                                            {/* Add a comma after the first timing */}
                                            {availableIndex === 0 && ", "}
                                          </div>
                                        ))
                                    ) : (
                                      <span>No available timings</span>
                                    )}

                                  </span>
                                </li>

                                {/* Languages Section */}
                                <li className="banner_pragraph_color fs-s fw-500 mt-1">
                                  <span className="fw-700 text-dark">Speaks</span>
                                  <span className="ms-3">:</span>
                                  <span className="px-3">
                                    {item?.trainerDetails?.language ? (
                                      // Check if 'language' is a string and split it, else use the array directly
                                      Array.isArray(item.trainerDetails.language)
                                        ? item.trainerDetails.language.map((language, index) => (
                                          <span key={index}>
                                            {language}
                                            {index < item.trainerDetails.language.length - 1 ? ", " : ""}
                                          </span>
                                        ))
                                        : item.trainerDetails.language.split(',').map((language, index) => (
                                          <span key={index}>
                                            {language.trim()}
                                            {index < item.trainerDetails.language.split(',').length - 1 ? ", " : ""}
                                          </span>
                                        ))
                                    ) : (
                                      <span>Language data is unavailable</span>
                                    )}
                                  </span>

                                </li>
                              </ul>

                              {/* Pricing Section */}
                              <div className="text-center pricing">
                                {/* Check if there are sessions in the batch */}
                                {item?.courses?.[0]?.batches?.[0]?.sessions?.length > 0 ? (
                                  <div className="actualAmount  mt-3 mb-0">
                                    <FaIndianRupeeSign />
                                    {/* Display the price and original price */}
                                    
                                    {item?.courses?.[0]?.batches?.[0]?.sessions[0]?.originalPrice && (
                                      <span className="original-price">
                                        <del>{item?.courses?.[0]?.batches?.[0]?.sessions[0]?.originalPrice}</del>
                                      </span>
                                    )}
                                    {/* Check for fee type */}
                                    {item?.courses?.[0]?.batches?.[0]?.feetype &&
                                      item?.courses?.[0]?.batches?.[0]?.feetype.toLowerCase() === 'course' ? (
                                      <> / {item?.courses?.[0]?.batches?.[0]?.feetype}</>
                                    ) : (
                                      <> / {item?.courses?.[0]?.batches?.[0]?.sessions[0]?.sessions} {item.courses?.[0]?.batches?.[0].feetype}</>
                                    )}
                                  </div>
                                ) : (
                                  <div className="actualAmount mt-3 mb-0">Free Course</div>
                                )}
                              </div>

                              {/* Course Fee/Type Section */}
                              <div className="app text-start ">
                                <span className="teks_bg_orange text-center fs-13 py-1 px-1 rounded mt-2" style={{ width: "150px" }}>
                                  {/* Again check if there are sessions */}
                                  {item?.courses?.[0]?.batches?.[0]?.sessions?.length > 0 ? (
                                    <>
                                      <FaIndianRupeeSign />
                                      {/* Price from the first session */}
                                      {item?.courses?.[0]?.batches?.[0]?.sessions[0]?.price}
                                      {item?.courses?.[0]?.batches?.[0]?.feetype && item?.courses?.[0]?.batches?.[0]?.feetype.toLowerCase() === "course" ? (
                                        <> / {item?.courses?.[0]?.batches?.[0]?.feetype}</>
                                      ) : (
                                        <> / {item?.courses?.[0]?.batches?.[0]?.sessions[0]?.sessions} {item.courses?.[0]?.batches?.[0].feetype}</>
                                      )}
                                    </>
                                  ) : (
                                    <span>Free Course</span>
                                  )}
                                </span>
                              </div>
                            </div>

                            <InstructorPopup trainer={trainer} parseData={parseData} trainerOptions={trainerOptions} courseName={courseName} filteredCourse={filteredCourse} item={item} categoryName={categoryName} trainerIds={item.trainerDetails.availableTrainerId} />
                          </div>
                        </div>

                      </div>
                    ))
                  ) : (
                    <p className="text-center">No trainers available</p>
                  )}
                </div>
              </div>



              <Requirements filteredCourse={filteredCourse} cleanedHTML={cleanedHTML} />
              <Rating courseName={originalCourse} />
            </div>
            <Stickyform courseName={originalCourse} categoryName={originalCategory} />
          </div>
        </div>
      </div>
      <RelatedCourses relatedCourse={relatedCourse} categoryName={originalCategory} courseName={originalCourse} />

    </div>
  );
};

export default SingleCourseLandingPage;
import React from "react";
import "../../../assets/css/about1.css";
import { Link } from "react-router-dom";
import teacherr from "../../../assets/images/RelationalManager.jpg";
import teacher from "../../../assets/images/zaheerudin.png";
import teacher8 from "../../../assets/images/mrbhaskar.png";
import laxmi from "../../../assets/images/laxmi.png";
import Page10 from "../home/Page10";
import Page6 from "../home/Page6";
import bhavitha from "../../../assets/images/bavitha1.png";
import learningImageMain from "../../../assets/images/about.png";

import { HiOutlineLightBulb } from "react-icons/hi";
import { GiCompass } from "react-icons/gi";
import { useEffect } from "react";
import divyani from "../../../assets/images/Divyani 1.png";
import rajkumar from "../../../assets/images/rajkumar.png";
import lipika from "../../../assets/images/lipika.png";
import Zoha from "../../../assets/images/zoha.png";
const About1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (

    <div>

      <section className="about_main" data-black-overlay="5">
        <div className="about_content">
          <span className="about_header bg-primary-opacity text-uppercase">
            Teks Skill Hub
          </span>
          <h1 className="title text-white fw-bold">
            Opening Doors to Craft Your Passion!
            <br />
            Join Today to Excel in Your Art
          </h1>

          <Link className="rbt-btn btn-sm mt-lg-5" to="/course">
            <span className="text-light">
              View Course <i className="feather-arrow-right"></i>
            </span>
          </Link>
        </div>
      </section>
      {/*  home section content */}
      <div className="my-5 container-fluid relative">
        <div className="row g-5 align-items-center learningcart">
          <div className="col-lg-6 ">
            <div className="image-container container">
              <img
                alt="Education Images"
                className="img-fluid learningImageMain"
                src={learningImageMain}
              />

            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="pl-3 ">
              <div className="mb-4 badge-container">
                <span className="badge2">Know About Us</span>
                <h2 className="mt-3 title fw-bold">Teks Skill Hub <br /> Your Passion-Our Priority</h2>
              </div>
              <p className="mb-4 heading2 headingdescription pe-5">
                Explore the Creator inside you with Teks Skill Hub! We focus on languages, music, dance, yoga, and 100+ courses. Our goal is to train individuals to craft their passion into expertise. Empower them to explore, create, and excel in what they love the most.
              </p>
              <div className="d-flex flex-column gap-3 mb-4 p-lg-5 features">
                <div className="d-flex align-items-start hover-box feature-item">
                  <div className="me-3 icon-container">
                    <i className="icon1 feather-heart text-white rounded-circle p-2"><HiOutlineLightBulb className="light" /></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="heading3">Vision</h6>
                    <p className="heading4">
                      To be a beacon of cultural heritage and artistic expression, inspiring individuals to explore and refine their innate talents through traditional and contemporary art forms.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start hover-box feature-item">
                  <div className="me-3 icon-container">
                    <i className="icon2 feather-book text-white rounded-circle p-2"><GiCompass className="light" /></i>
                  </div>
                  <div className="feature-content">
                    <h6 className="heading3 gap-1">Mission</h6>
                    <p className="heading4">
                      Our mission is to foster a vibrant community where all ages connect with music, dance, and languages, enhancing skills and cultural appreciation.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/*  home section content */}
      <div class="callto-action-area  ">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6">
              <div class="card rounded px-3 bg-white card-equal-height shad">
                <div class="row align-items-center g-0">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/mi3gJturJAc?si=TpuDcGOEl6WYVkRJ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-lg-6 ">
              <div class="card rounded bg-white card-equal-height shad">
                <div class="row align-items-center g-0">
                  <div class="col-12 p-3">
                    <div class="inner">
                      <div class="mb-3 rbt-category ">
                        <Link
                          href="/01-main-demo#"
                          class="text-decoration-none bg_orange text-white"
                        >
                          Top Teacher
                        </Link>
                      </div>
                      <h4 class="mb-2 title fw-bold">
                        Craft Art from Home with Teks Skill Hub's Online
                        Courses!
                      </h4>
                      <p class="mb-3 title fw-bold">
                        Top instructors from around the world
                      </p>
                      <div class="read-more-btn  ">
                        <Link class="rbt-btn btn-sm " to="/becomeatrainer">
                          <span data-text="Join Now" className="text-white">
                            Join Now
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  home section content */}
      <div className="mt-4">
        <Page6 />
      </div>

      <section className="py-5 container bg-white">
        <div className="text-center">
          <span className="bg_orange px-3 py-1 fs-6 fst-normal text-white rounded-pill bg-opacity-25">
            Our Technology Experts
          </span>
          <h2 className="title fw-bold mt-4">
            Let our Tech Panel light your way and boost your drive!
          </h2>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <p className="intructiondesign">
                Our technical professionals are committed to equipping you with
                the knowledge and technical expertise necessary to advance your
                skills and mastery in your chosen field. Don’t wait—take the
                first step towards elevating your capabilities today
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-center">
          <div className="tech-card ">
            <img src={teacher} alt="Card Image 1" className="techcard-image" />
            <h2 className="techcard-title text-center">Zaheeruddin Shaik</h2>
            <p className="techcard-content text-center">Managing Director</p>
          </div>
          <div className="tech-card">
            <img
              src={teacher8}
              alt="Card Image 2"
              className="techcard-image mt-1"
            />
            <h2 className="techcard-title text-center">Bhaskar S</h2>
            <p className="techcard-content text-center">Project Manager</p>
          </div>
          <div className="tech-card">
            <img src={teacherr} alt="Card Image 3" className="techcard-image" />
            <h2 className="techcard-title text-center">Bhargavi G</h2>
            <p className="techcard-content text-center">
              Relationship Manager
            </p>
          </div>
          <div className="tech-card">
            <img src={laxmi} alt="Card Image 4" className="techcard-image" />
            <h2 className="techcard-title text-center">Srilakshmi A</h2>
            <p className="techcard-content text-center">Software Developer</p>
          </div>
          <div className="tech-card">
            <img src={rajkumar} alt="Card Image 1" className="techcard-image" />
            <h2 className="techcard-title text-center">RajKumar</h2>
            <p className="techcard-content text-center">Software Developer</p>
          </div>
          <div className="tech-card">
            <img
              src={bhavitha}
              alt="Card Image 2"
              className="techcard-image mt-1"
            />
            <h2 className="techcard-title text-center">Bhavitha</h2>
            <p className="techcard-content text-center">Software Developer</p>
          </div>
          <div className="tech-card">
            <img src={Zoha} alt="Card Image 3" className="techcard-image" />
            <h2 className="techcard-title text-center">Zoha</h2>
            <p className="techcard-content text-center">
              Relationship Executive
            </p>
          </div>

        </div>
      </section>
      {/*  home section content */}
      <Page10 />
    </div>
  );
};

export default About1;

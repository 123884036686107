import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input } from 'react-select/animated'
import '../../assets/css/login.css'
import logo from '../../assets/images/Teks_Skill_HubLogo_05.png'
import { REAPI } from '../../serviceLayer/Interceptor'
import { GoCheckCircleFill } from 'react-icons/go'
import { FiEye, FiEyeOff } from 'react-icons/fi'

export const ForgotPassword = () => {

    // show password state
    const [showPwd, setShowPwd] = useState(false)
    const [showPwd1, setShowPwd1] = useState(false)


    const navigation = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // states
    // otp Disable
    const [OtpDisable, setOptDisable] = useState(false)
    // state for hiding the verification
    const [isInputs, setInputs] = useState({
        mailVerification: true,
        loading: false,
        generateOTPLoading: false,
    })
    // mail OTP input view handeling

    const [OTPinput, setOTPinput] = useState(true)
    const [isValidMail, setVaildMail] = useState(false)
    const [MailOTP, setMailOTP] = useState("")
    // form data state
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: ""
    })
    // error state
    const [error, setError] = useState({})

    // handle Submite
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validations();
        if (Object.keys(formErrors) != 0) {
            // console.log(formErrors,"formErrors");
            return setError(formErrors);
        }
        try {
            const response = await REAPI.post(`/user/forget`, formData)
            setFormData({
                ...formData,
                email: "",
                password: "",
                confirmPassword: ""
            })
            navigation("/Login")
        } catch (error) {
            setInputs({ ...isInputs, loading: false })
        }
        finally {
            setInputs({ ...isInputs, loading: false })

        }
        // console.log(formData,"DAta")
    }

    // input handle function
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value

        }))
        setError(prev => ({
            ...prev,
            [id]: ""

        }))
        // console.log(formData,"data")
    }

    // validations
    const validations = () => {
        const formErrors = {};
        if (!formData.email) {
            formErrors.email = "Email is required";
            return formErrors;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Invalid email address";
            return formErrors;
        }
        if (formData.password.length < 6) {
            formErrors.password = "password should contain atleast 6 character";
            return formErrors;
        }
        if (formData.confirmPassword !== formData.password) {
            formErrors.confirmPassword = "password must match";
            return formErrors;
        }

        return formErrors;
    }

    // mail verification
    useEffect(() => { setVaildMail(false) }, [formData.email])
    const handleVaildMail = async (e) => {
        e.preventDefault();
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setError({
                ...error,
                email: "Invalid email address"
            })
            return;
        }
        const reqData = {
            email: formData.email
        }

        try {
            // console.log("mail sent")
            setInputs({ ...isInputs, generateOTPLoading: true })
            const response = await REAPI.post("/otp/send", reqData)

            setVaildMail(!isValidMail)
        } catch (error) {
            setError({
                ...error,
                SubmitError: error.response.data.error,
            })
            setInputs({ ...isInputs, generateOTPLoading: false })
        }
        finally {
            setInputs({ ...isInputs, generateOTPLoading: false })
        }
        // console.log("clicked email")
    }
    const handleVerifyOtp = async (e) => {
        e.preventDefault()
        if (!MailOTP) {
            setError({ ...error, email: "please enter otp" })
            return
        }
        const reqData = {
            email: formData.email,
            otpCode: parseInt(MailOTP),

        }

        try {
            setOptDisable(true)
            // setInputs({ ...isInputs, generateOTPLoading: true })
            const response = await REAPI.post("/otp/verify", reqData)
            if (response.status == 200) {
                setOTPinput(false)
                setInputs(prev => ({
                    ...prev,
                    mailVerification: false
                }))
                setFormData({
                    ...formData,
                    isVerified: true
                })
            }
        } catch (error) {
            // setInputs({ ...isInputs, generateOTPLoading: false })
            setOptDisable(false)
            setError({
                ...error,
                SubmitError: error.response.data.error,
            })
            // console.log(error)
        } finally {
            setOptDisable(false)
            // setInputs({ ...isInputs, generateOTPLoading: false })
        }

        // console.log("verified")

        // console.log("verified otp")
    }

    return (
        <div className="login_bg_image w-full vh-100">
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="col-12 text-center mt-sm-5 mb-1 text-white-50">
                        <img src={logo} alt="" srcset="" style={{ width: "230px" }} />
                    </div>
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="shadow mt-4 bg-white p-4 rounded">
                            <h2 className="text-center mb-4 teks_blue fs-16 fw-500">Forgot Password! </h2>
                            <form>
                                <div class="mb-3">
                                    <label for="email" class=" fs-13 fw-500 mb-2">Email <span className="text-danger">*</span></label>
                                    <div className="position-relative">
                                        <input type="email" class="form-control fs-s bg-form text_color input_bg_color" id="email" aria-describedby="emailHelp" placeholder='name@example.com' onChange={(e) => { handleChange(e) }} disabled={!isInputs.mailVerification} value={formData.email} />
                                        {OTPinput && formData && formData.email && (
                                            <div class="mt-3 d-flex gap-1">
                                                {isValidMail && (<input type="number" class="form-control fs-s bg-form text_color input_bg_color" id="otp" aria-describedby="emailHelp" placeholder='OTP' onChange={(e) => { setMailOTP(e.target.value); setError({ ...error, email: "", SubmitError: "" }) }} onInput={(e) => { if (e.target.value > 6) e.target.value = e.target.value.slice(0, 6) }} />)}
                                                <button className={` btn teks_bg_blue text-white ${!isValidMail ? "w-100" : "w-50"}`} onClick={!isValidMail ? (e) => { handleVaildMail(e) } : (e) => { handleVerifyOtp(e) }} disabled={!isValidMail ? isInputs.generateOTPLoading : OtpDisable}>{isValidMail ? <span>Verify OTP</span> : <span>Generate OTP</span>}</button>
                                            </div>
                                        )}
                                        {!OTPinput && (<div className="position-absolute end-0 px-2 py-1 text-success top-0">
                                            <GoCheckCircleFill />
                                        </div>)}
                                    </div>
                                    {error && error.email && (
                                        <p className="text-danger m-0 fs-xs">
                                            {error.email}
                                        </p>
                                    )}
                                </div>
                                <div class="mb-3">
                                    <label for="password" class=" fs-13 fw-500 mb-2">Password <span className="text-danger">*</span></label>
                                    <div className="position-relative">
                                    <input type={showPwd?"text":"password"} class="form-control fs-s bg-form text_color input_bg_color" id="password" aria-describedby="emailHelp" placeholder='Enter Password ' onChange={(e) => { handleChange(e) }} value={formData.password} />
                                    {error && error.password && (
                                        <p className="text-danger m-0 fs-xs">
                                            {error.password}
                                        </p>
                                    )}
                                   {showPwd?(<div className="position-absolute end-0 px-2 py-1 text-dark top-0">
                                            <FiEye onClick={()=>setShowPwd(!showPwd)}/>
                                        </div>):(<div className="position-absolute end-0 px-2 py-1 text-dark top-0">
                                            <FiEyeOff onClick={()=>setShowPwd(!showPwd)} />
                                        </div>)}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="confirmPassword" class=" fs-13 fw-500 mb-2">Confirm Password  <span className="text-danger">*</span></label>
                                    <div className="position-relative">
                                    <input type={showPwd1?"text":"password"}class="form-control fs-s bg-form text_color input_bg_color" id="confirmPassword" aria-describedby="emailHelp" placeholder='Confirm Password ' onChange={(e) => { handleChange(e) }} value={formData.confirmPassword} />
                                    {error && error.confirmPassword && (
                                        <p className="text-danger m-0 fs-xs">
                                            {error.confirmPassword}
                                        </p>
                                    )}
                                    {showPwd1?(<div className="position-absolute end-0 px-2 py-1 text-dark top-0">
                                            <FiEye onClick={()=>setShowPwd1(!showPwd1)}/>
                                        </div>):(<div className="position-absolute end-0 px-2 py-1 text-dark top-0">
                                            <FiEyeOff onClick={()=>setShowPwd1(!showPwd1)} />
                                        </div>)}</div>
                                </div>
                                {/* <div class="mb-3">
                                    <span className="text-primary text-end"><Link to="/authentication/Login">Login ?</Link></span>
                                </div> */}
                                {error && error.SubmitError && (
                                    <p className="text-danger m-0 fs-xs">
                                        {error.SubmitError}
                                    </p>
                                )}
                                <button className={`btn teks_bg_blue text-white w-100`} onClick={handleSubmit} disabled={isInputs.mailVerification}>{isInputs.loading ? "Submitting" : "Submit"}</button>
                            </form>
                        </div>
                    </div>
                    {/* <div className="col-12 col-lg-6"></div> */}
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { REAPI } from '../../../../../serviceLayer/Interceptor';

export const RelatedCourses = ({relatedCourse}) => {

    // scroll to top 
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
      const slugify = (text) => {
        return text
          .toString()
          .normalize("NFD") // Normalize accented characters
          .replace(/[\u0300-\u036f]/g, "") // Remove accents
          .toLowerCase()
          .replace(/[^a-z0-9 +\-]/g, "") // Remove non-alphanumeric characters
          .replace(/\s+/g, "-") // Replace spaces with hyphens
          .replace(/-+/g, "-"); // Replace multiple hyphens with one
      };
   return (
        <div className="container">
            <div className="mt-5">
                <p className="bg_top_course fw-600 fs-14 text-light bg_orange bg-opacity-25">
                    More Similar Courses
                </p>
                <div className="mt-1 d-flex justify-content-between">
                    <h4 className="teks_blue fs-30 fw-600">
                        <span className="teks_orange">Related Courses</span>
                    </h4>
                </div>
                <div className="mt-1">
                    <div className="row g-4">
                        {relatedCourse?.map((card) => (
                            <div
                                className="col-lg-3 col-md-6 col-sm-6 col-12"
                                key={card.id}
                            >
                                <Link
                                    to={`/course/${slugify(card.categoryName)}/${slugify(card.courseName)}`}
                                    onClick={handleScrollToTop}
                                >
                                    <div className="carousel-item active rbt-card d-flex flex-column justify-content-between ">
                                        <div>
                                            <img
                                                src={card.imageURL}
                                                className="d-block w-100 courselist-img"
                                                alt="cardImage"
                                            />
                                            <div className="mt-2 px-3">
                                                <p className="">
                                                    {Array.from({ length: 5 }, (_, index) => {
                                                        const fullStar =
                                                            index + 1 <= Math.floor(card?.courseRating);
                                                        const halfStar =
                                                            index + 1 > Math.floor(card?.courseRating) &&
                                                            index < card?.courseRating;

                                                        if (fullStar) {
                                                            return (
                                                                <FaStar
                                                                    key={index}
                                                                    className="mb-1  review_color"
                                                                />
                                                            );
                                                        } else if (halfStar) {
                                                            return (
                                                                <FaStarHalfAlt
                                                                    key={index}
                                                                    className="mb-1  review_color"
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <FaRegStar
                                                                    key={index}
                                                                    className="mb-1  review_color"
                                                                />
                                                            );
                                                        }
                                                    })}
                                                    <span className="text-dark">
                                                        ({card.courseRating} Reviews)
                                                    </span>
                                                </p>
                                                <h5 className="fw-700 text-dark">
                                                    {card.courseName}
                                                </h5>

                                                {/* <p className="banner_pragraph_color">
                            {card.courseTitle}
                          </p> */}
                                                <p className="banner_pragraph_color">
                                                    {card.courseHeading}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between px-3 py-2">
                                            <p className="m-0 view-span teks_blue fw-500">
                                                Know More{" "}
                                                <span className="fs-5 fw-500 under-line1">→</span>
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
